import { HOST_URL, REFER_URL } from "./url_helper"

export function ApiPostMethodWithToken(url, data, token, method = "POST") {
  return fetch(url, {
    method: method,
    headers: { "Content-Type": "application/json", Authorization: token },
    body: JSON.stringify(data),
  })
    .then(resp => resp.json())
    .then(function (result) {
      if (
        (result.message == "Invalid Token" ||
          result.message == "Unauthorized") &&
        result.islogin === true
      ) {
        alert("Your session has expired!, Please login to continue")
        localStorage.clear()
        window.location.href = `${REFER_URL}#/Login`
      } else {
        return result
      }
    })
}

export function ApiGetMethodWithToken(url, data, token, method = "GET") {
  return fetch(url, {
    method: method,
    headers: { "Content-Type": "application/json", Authorization: token },
  })
    .then(resp => resp.json())
    .then(function (result) {
      return result
    })
}
