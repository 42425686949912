import React, { useEffect, useState } from "react"
import {
  TextField,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  MenuItem,
  IconButton,
  Checkbox,
} from "@mui/material"
import Modal from "react-bootstrap/Modal"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import CloseIcon from "@mui/icons-material/Close"
import { Col, Input, Label, Row } from "reactstrap"
import Swal from "sweetalert2"

const AddUserDetail = ({
  open,
  handleClose,
  operation,
  userData,
  fetchData,
}) => {
  const [planNames, setplanNames] = useState([])
  const [planLists, setPlanList] = useState([])
  const [status, setStatus] = useState("")
  const [frontImage, setFrontImage] = useState("")
  const [backImage, setBackImage] = useState(null)
  const [panImage, setPanImage] = useState(null)
  const [profileImage, setProfileImage] = useState(null)
  const [formData, setFormData] = useState({
    name: "",
    mobno: "",
    email: "",
    password: "",
    gender: "",
    paymentMode: "",
    amount: "",
    transactionId: "",
    status: "",
    panImage: "",
    profileImage: "",
    frontImage: "",
    backImage: "",
  })

  const handleImageUpload = async (file, fieldId) => {
    const formData = new FormData()
    formData.append("images", file)
    const url = `${HOST_URL}api/upload`
    await fetch(url, {
      method: "POST",
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        if (fieldId == "profileImg") {
          setProfileImage(
            `${HOST_URL}public/img/tempimg/${response.files[0].filename}`
          )
          setFormData(prevdata => ({
            ...prevdata,
            profileImage: response.files[0].filename,
          }))
        }
        if (fieldId == "panImg") {
          setPanImage(
            `${HOST_URL}public/img/tempimg/${response.files[0].filename}`
          )
          setFormData(prevdata => ({
            ...prevdata,
            panImage: response.files[0].filename,
          }))
        }
        if (fieldId == "aadFrontImg") {
          setFrontImage(
            `${HOST_URL}public/img/tempimg/${response.files[0].filename}`
          )
          setFormData(prevdata => ({
            ...prevdata,
            frontImage: response.files[0].filename,
          }))
        }
        if (fieldId == "aadBackImg") {
          setBackImage(
            `${HOST_URL}public/img/tempimg/${response.files[0].filename}`
          )
          setFormData(prevdata => ({
            ...prevdata,
            backImage: response.files[0].filename,
          }))
        }
      })
      .catch(error => {
        console.error("Error uploading image:", error)
        throw error
      })
  }

  const handleImageDelete = async (filename, fieldId) => {
    console.log(fieldId,filename);
    
    try {
      const response = await fetch(`${HOST_URL}api/delete/${filename}`, {
        method: "DELETE",
        body: JSON.stringify({ imageType: "userImage" }),
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (!response.ok) {
        throw new Error(`Deletion failed with status ${response.status}`)
      }

      if (fieldId == "profileImg") {
        setProfileImage("")
        setFormData(prevdata => ({
          ...prevdata,
          profileImage: "",
        }))
        document.getElementById("profileImage").value = ""
      }
      if (fieldId == "panImg") {
        setPanImage("")
        setFormData(prevdata => ({
          ...prevdata,
          panImage: "",
        }))
        document.getElementById("panImage").value = ""
      }
      if (fieldId == "aadFrontImg") {
        setFrontImage("")
        setFormData(prevdata => ({
          ...prevdata,
          frontImage: "",
        }))
        document.getElementById("frontImage").value = ""
      }
      if (fieldId == "aadBackImg") {
        setBackImage("")
        setFormData(prevdata => ({
          ...prevdata,
          backImage: "",
        }))
        document.getElementById("backImage").value = ""
      }
    } catch (error) {
      console.error("Error deleting image", error)
    }
  }
  useEffect(() => {
    if (!open) {
      setFormData({
        name: "",
        mobno: "",
        email: "",
        password: "",
        changePassword: false,
        gender: "",
        paymentMode: "",
        amount: "",
        transactionId: "",
        status: "",
        panImage: "",
        profileImage: "",
        frontImage: "",
        backImage: "",
      })
      setErrors({})
      setProfileImage("")
      setPanImage("")
      setFrontImage("")
      setBackImage("")
    } else {
      if (userData && userData.singleuser && operation === "Edit User") {
        setFormData({
          user_id: userData?.singleuser?._id,
          name: userData?.singleuser?.name,
          mobno: userData?.singleuser?.mobno,
          email: userData?.singleuser?.email,
          password: "",
          gender: userData?.singleuser?.gender,
          changePassword: false,
          status: userData?.singleuser?.status,
          panImage: userData?.singleuser?.panImage || "",
          profileImage: userData?.singleuser?.profileImage || "",
          frontImage: userData?.singleuser?.frontImage || "",
          backImage: userData?.singleuser?.backImage || "",
        })
        setProfileImage(
          userData?.singleuser?.profileImage
            ? `${HOST_URL}public/img/userimg/${userData?.singleuser?.profileImage}`
            : ""
        )
        setPanImage(
          userData?.singleuser?.panImage
            ? `${HOST_URL}public/img/userimg/${userData?.singleuser?.panImage}`
            : ""
        )
        setFrontImage(
          userData?.singleuser?.frontImage
            ? `${HOST_URL}public/img/userimg/${userData?.singleuser?.frontImage}`
            : ""
        )
        setBackImage(
          userData?.singleuser?.backImage
            ? `${HOST_URL}public/img/userimg/${userData?.singleuser?.backImage}`
            : ""
        )
      } else {
        var registeredBy = JSON.parse(localStorage.getItem("authUser"))
        setFormData({
          user_id: "",
          name: "",
          mobno: "",
          email: "",
          password: "",
          gender: "",
          registeredBy: registeredBy._id,
          paymentMode: "",
          amount: "",
          transactionId: "",
          planName: "",
          changePassword: false,
          status: "",
          panImage: "",
          profileImage: "",
          frontImage: "",
          backImage: "",
        })
        setProfileImage("")
        setPanImage("")
        setFrontImage("")
        setBackImage("")
      }
    }
  }, [open, userData, operation])

  const [errors, setErrors] = useState({})
  const token = JSON.parse(localStorage.getItem("token"))

  useEffect(() => {
    fetchplanNames()
  }, [])

  const fetchplanNames = () => {
    const url = `${HOST_URL}plan/viewAllPLan`
    const data = {}
    ApiPostMethodWithToken(url, data, token)
      .then(response => {
        if (response.error === false && Array.isArray(response.planList)) {
          setPlanList(
            response.planList.filter(plans => plans.addOnPlan !== true)
          )
          const names = response.planList
            .filter(plans => plans.addOnPlan !== true)
            .map(plan => plan.planName)
          setplanNames(names)
        } else {
          console.error("Error fetching plan names:", response.message)
        }
      })
      .catch(error => console.error("Error fetching plan names:", error))
  }

  const handleChange = e => {
    const { name, value } = e.target

    if (name === "password") {
      if (name === "password") {
        setFormData({ ...formData, password: value })
      } else {
        setFormData({ ...formData, [name]: value })
      }
    } else if (name === "planName") {
      const selectedPlan = planLists.find(plan => plan.planName === value)
      setFormData({
        ...formData,
        [name]: value,
        amount: selectedPlan ? selectedPlan.planOfferAmount : "",
        amountDisabled: true,
      })
    } else if (name === "status") {
      setStatus(value)
      setFormData({ ...formData, [name]: value })
    } else {
      setFormData({ ...formData, [name]: value })
    }
  }

  const handleTogglePasswordChange = () => {
    console.log("Before toggle:", formData)
    setFormData(prevFormData => ({
      ...prevFormData,
      changePassword: !prevFormData.changePassword,
      password: !prevFormData.changePassword ? "" : prevFormData.password,
    }))
    console.log("After toggle:", formData)
  }

  const validateForm = data => {
    let errors = {}

    if (!data.name.trim()) {
      errors.name = "Name is required"
    }

    if (!data.email.trim()) {
      errors.email = "Email is required"
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid"
    }

    if (
      (operation === "Add User" ||
        (operation === "Edit User" && formData.changePassword)) &&
      !/^[a-zA-Z0-9!@#$%^&*]{8,20}$/.test(data.password)
    ) {
      errors.password =
        "Password must be between 8 and 20 characters long and can contain letters, numbers, and special characters (!@#$%^&*)"
    }

    if (!data.profileImage.trim() || !data.panImage.trim() || !data.backImage.trim() || !data.frontImage.trim()) {
      Swal.fire({
        icon: "info",
        title: "Warning!",
        text: "Please upload all the given files in the form!",
      })
    }
    if (!data.profileImage.trim()) {
      errors.profileImage = "Profile Image is required"
    }

    if (!data.panImage.trim()) {
      errors.panImage = "PAN Card Image is required"
    }

    if (!data.backImage.trim()) {
      errors.backImage = "Back Image is required"
    }

    if (!data.frontImage.trim()) {
      errors.frontImage = "Front Image is required"
    }

    if (!data.mobno.trim()) {
      errors.mobno = "Mobile No. is required"
    } else if (!/^\d+$/.test(data.mobno)) {
      errors.mobno = "Mobile No. should contain only numbers"
    } else if (data.mobno.length !== 10) {
      errors.mobno = "Mobile No. must be exactly 10 digits long"
    }

    if (!data.gender) {
      errors.gender = "Gender is required"
    }

    if (!data.status) {
      errors.status = "Status is required"
    }

    if (operation === "Add User") {
      if (!data.planName) {
        errors.planName = "Plan Name is required"
      }

      if (!data.paymentMode) {
        errors.paymentMode = "Payment Mode is required"
      }

      if (!data.amount) {
        errors.amount = "Amount is required"
      }
    }

    return errors
  }

  const handleSubmit = () => {
    const validationErrors = validateForm(formData)
    console.log("validationErrors", validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      const userUrl = `${HOST_URL}user/${
        operation === "Add User" ? "addUser" : "editUser"
      }`
      const userFormData = formData
      ApiPostMethodWithToken(userUrl, userFormData, token)
        .then(userData => {
          if (userData.error === false) {
            if (operation === "Add User") {
              const plandetails = planLists.filter(
                name => name.planName === formData.planName
              )
              const planDuration = parseInt(plandetails[0].planDuration)
              const paymentUrl = `${HOST_URL}payment`
              const paymentData = {
                userId: userData.user._id,
                userName: userData.user.name,
                planId: plandetails[0].planId,
                planName: plandetails[0].planName,
                planAmount: formData.amount,
                paymentMode: formData.paymentMode,
                registeredBy: plandetails[0].registeredBy,
                planDuration: planDuration,
                transactionId: formData.transactionId,
              }
              ApiPostMethodWithToken(paymentUrl, paymentData, token).then(
                paymentData => {
                  if (paymentData.error === false) {
                    handleClose()
                    Swal.fire({
                      icon: "success",
                      title: userData.message,
                      showConfirmButton: false,
                      timer: 1500,
                      onClose: () => {
                        window.location.reload()
                      },
                    })
                  }
                }
              )
            } else {
              handleClose()
              Swal.fire({
                icon: "success",
                title: userData.message,
                showConfirmButton: false,
                timer: 1500,
                onClose: () => {
                  window.location.reload()
                },
              })
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: userData.message,
            })
          }
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.message,
          })
          console.log(`Error: ${error}`)
        })
    } else {
      setErrors(validationErrors)
    }
  }
  return (
    <Modal show={open} onHide={handleClose} centered>
      <div style={{ padding: 20, maxHeight: "600px", overflow: "auto" }}>
        <div
          className="modal-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5>{operation === "Add User" ? "Add User" : "Edit User"}</h5>
          <IconButton onClick={handleClose} style={{ marginRight: "-10px" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <Row>
          <Col lg={6} md={6} xs={12}>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={errors.name ? true : false}
              helperText={errors.name}
            />
          </Col>
          <Col lg={6} md={6} xs={12}>
            <TextField
              label="Mobile No."
              name="mobno"
              type="number"
              value={formData.mobno}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={errors.mobno ? true : false}
              helperText={errors.mobno}
              onWheel={e => e.target.blur()}
            />
          </Col>
        </Row>
        {operation === "Edit User" ? (
          <>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.email ? true : false}
                  helperText={errors.email}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Label style={{ fontWeight: 800 }}>Profile Image</Label>

                {profileImage != "" ? (
                  <div className="d-flex">
                    <div
                      className="preview-container"
                      style={{ position: "relative", display: "inline-block", background:'ghostwhite', padding:'10px',borderRadius:'5px' }}
                    >
                      <img
                        style={{
                          height: "60px",
                        }}
                        src={profileImage}
                        alt="Profile Image Preview"
                        className="image-preview"
                      />
                      <Button
                        onClick={() =>
                          handleImageDelete(formData.profileImage, "profileImg")
                        }
                        variant="text"
                        size="small"
                        style={{
                          color: "#000000",
                          position: "absolute",
                          padding: "0px",
                          top: "9px",
                          right: "-11px",
                        }}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Input
                    type="file"
                    id="profileImage"
                    name="profileImage"
                    accept="image/*"
                    onChange={e =>
                      handleImageUpload(e.target.files[0], "profileImg")
                    }
                    error={errors.profileImage ? true : false}
                    helperText={errors.profileImage}
                    className="form-control loginRegInput"
                    multiple={false}
                    maxLength={1}
                  />
                )}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Label style={{ fontWeight: 800 }}>Pan Image</Label>

                {panImage != "" ? (
                  <div className="d-flex">
                    <div
                      className="preview-container"
                      style={{ position: "relative", display: "inline-block", background:'ghostwhite', padding:'10px',borderRadius:'5px' }}
                    >
                      <img
                        style={{
                          height: "60px",
                        }}
                        src={panImage}
                        alt="Pan Preview"
                        className="image-preview"
                      />
                      <Button
                        onClick={() =>
                          handleImageDelete(formData.panImage, "panImg")
                        }
                        variant="text"
                        size="small"
                        style={{
                          color: "#000000",
                          position: "absolute",
                          padding: "0px",
                          top: "9px",
                          right: "-11px",
                        }}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Input
                    type="file"
                    id="panImage"
                    name="panImage"
                    accept="image/*"
                    onChange={e =>
                      handleImageUpload(e.target.files[0], "panImg")
                    }
                    error={errors.panImage ? true : false}
                    helperText={errors.panImage}
                    className="form-control loginRegInput"
                    multiple={false}
                    maxLength={1}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Label style={{ fontWeight: 800, margin: "10px 0px 5px" }}>
                  Adhar Card Image
                </Label>

                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Label>Front Image</Label>

                    {frontImage != "" ? (
                      <div className="d-flex">
                        <div className="preview-container">
                          <div
                            className="image-container"
                            style={{
                              position: "relative",
                              display: "inline-block",
                              background:'ghostwhite', padding:'10px',borderRadius:'5px'
                            }}
                          >
                            <img
                              style={{
                                height: "60px",
                              }}
                              src={frontImage}
                              alt="Front Image Preview"
                              className="image-preview"
                            />
                            <Button
                              onClick={() =>
                                handleImageDelete(
                                  formData.frontImage,
                                  "aadFrontImg"
                                )
                              }
                              variant="text"
                              size="small"
                              style={{
                                color: "#000000",
                                position: "absolute",
                                padding: "0px",
                                top: "9px",
                                right: "-11px",
                              }}
                            >
                              <CloseIcon style={{ color: "error" }} />
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Input
                        type="file"
                        id="frontImage"
                        name="frontImage"
                        accept="image/*"
                        onChange={e =>
                          handleImageUpload(e.target.files[0], "aadFrontImg")
                        }
                        error={errors.frontImage ? true : false}
                        helperText={errors.frontImage}
                        className="form-control loginRegInput"
                        multiple={false}
                        maxLength={1}
                      />
                    )}
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Label>Back Image</Label>

                    {backImage != "" ? (
                      <div className="d-flex">
                        <div className="preview-container">
                          <div
                            className="image-container"
                            style={{
                              position: "relative",
                              display: "inline-block",
                              background:'ghostwhite', padding:'10px',borderRadius:'5px'
                            }}
                          >
                            <img
                              style={{
                                height: "60px",
                              }}
                              src={backImage}
                              alt="Back Image Preview"
                              className="image-preview"
                            />
                            <Button
                              onClick={() =>
                                handleImageDelete(
                                  formData.backImage,
                                  "aadBackImg"
                                )
                              }
                              variant="text"
                              size="small"
                              style={{
                                color: "#000000",
                                position: "absolute",
                                padding: "0px",
                                top: "9px",
                                right: "-11px",
                              }}
                            >
                              <CloseIcon />
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Input
                        type="file"
                        id="backImage"
                        name="backImage"
                        accept="image/*"
                        onChange={e =>
                          handleImageUpload(e.target.files[0], "aadBackImg")
                        }
                        error={errors.backImage ? true : false}
                        helperText={errors.backImage}
                        className="form-control loginRegInput"
                        multiple={false}
                        maxLength={1}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
        {operation === "Add User" ? (
          <>
            <Row>
              <Col lg={6} md={6} xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.email ? true : false}
                  helperText={errors.email}
                />
              </Col>
              <Col lg={6} md={6} xs={12}>
                {(operation === "Add User" || formData.changePassword) && (
                  <TextField
                    label="Password"
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={errors.password}
                    helperText={errors.password || ""}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Label style={{ fontWeight: 800 }}>Profile Image</Label>

                {profileImage != "" ? (
                  <div className="d-flex">
                    <div
                      className="preview-container"
                      style={{ position: "relative", display: "inline-block", background:'ghostwhite', padding:'10px',borderRadius:'5px' }}
                    >
                      <img
                        style={{
                          height: "60px",
                        }}
                        src={profileImage}
                        alt="Profile Image Preview"
                        className="image-preview"
                      />
                      <Button
                        onClick={() =>
                          handleImageDelete(formData.profileImage, "profileImg")
                        }
                        variant="text"
                        size="small"
                        style={{
                          color: "#000000",
                          position: "absolute",
                          padding: "0px",
                          top: "9px",
                          right: "-11px",
                        }}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Input
                    type="file"
                    id="profileImage"
                    name="profileImage"
                    accept="image/*"
                    onChange={e =>
                      handleImageUpload(e.target.files[0], "profileImg")
                    }
                    error={errors.profileImage ? true : false}
                    helperText={errors.profileImage}
                    className="form-control loginRegInput"
                    multiple={false}
                    maxLength={1}
                  />
                )}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Label style={{ fontWeight: 800 }}>Pan Image</Label>

                {panImage != "" ? (
                  <div className="d-flex">
                    <div
                      className="preview-container"
                      style={{ position: "relative", display: "inline-block", background:'ghostwhite', padding:'10px',borderRadius:'5px' }}
                    >
                      <img
                        style={{
                          height: "60px",
                        }}
                        src={panImage}
                        alt="Pan Preview"
                        className="image-preview"
                      />
                      <Button
                        onClick={() =>
                          handleImageDelete(formData.panImage, "panImg")
                        }
                        variant="text"
                        size="small"
                        style={{
                          color: "#000000",
                          position: "absolute",
                          padding: "0px",
                          top: "9px",
                          right: "-11px",
                        }}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Input
                    type="file"
                    id="panImage"
                    name="panImage"
                    accept="image/*"
                    onChange={e =>
                      handleImageUpload(e.target.files[0], "panImg")
                    }
                    error={errors.panImage ? true : false}
                    helperText={errors.panImage}
                    className="form-control loginRegInput"
                    multiple={false}
                    maxLength={1}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Label style={{ fontWeight: 800, margin: "10px 0px 5px" }}>
                  Adhar Card Image
                </Label>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Label>Front Image</Label>

                    {frontImage != "" ? (
                      <div className="d-flex">
                        <div className="preview-container">
                          <div
                            className="image-container"
                            style={{
                              position: "relative",
                              display: "inline-block",
                              background:'ghostwhite', padding:'10px',borderRadius:'5px'
                            }}
                          >
                            <img
                              style={{
                                height: "60px",
                              }}
                              src={frontImage}
                              alt="Front Image Preview"
                              className="image-preview"
                            />
                            <Button
                              onClick={() =>
                                handleImageDelete(
                                  formData.frontImage,
                                  "aadFrontImg"
                                )
                              }
                              variant="text"
                              size="small"
                              style={{
                                color: "#000000",
                                position: "absolute",
                                padding: "0px",
                                top: "9px",
                                right: "-11px",
                              }}
                            >
                              <CloseIcon style={{ color: "error" }} />
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Input
                        type="file"
                        id="frontImage"
                        name="frontImage"
                        accept="image/*"
                        onChange={e =>
                          handleImageUpload(e.target.files[0], "aadFrontImg")
                        }
                        error={errors.frontImage ? true : false}
                        helperText={errors.frontImage}
                        className="form-control loginRegInput"
                        multiple={false}
                        maxLength={1}
                      />
                    )}
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Label>Back Image</Label>

                    {backImage != "" ? (
                      <div className="d-flex">
                        <div className="preview-container">
                          <div
                            className="image-container"
                            style={{
                              position: "relative",
                              display: "inline-block",
                              background:'ghostwhite', padding:'10px',borderRadius:'5px'
                            }}
                          >
                            <img
                              style={{
                                height: "60px",
                              }}
                              src={backImage}
                              alt="Back Image Preview"
                              className="image-preview"
                            />
                            <Button
                              onClick={() =>
                                handleImageDelete(
                                  formData.backImage,
                                  "aadBackImg"
                                )
                              }
                              variant="text"
                              size="small"
                              style={{
                                color: "#000000",
                                position: "absolute",
                                padding: "0px",
                                top: "9px",
                                right: "-11px",
                              }}
                            >
                              <CloseIcon />
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Input
                        type="file"
                        id="backImage"
                        name="backImage"
                        accept="image/*"
                        onChange={e =>
                          handleImageUpload(e.target.files[0], "aadBackImg")
                        }
                        error={errors.backImage ? true : false}
                        helperText={errors.backImage}
                        className="form-control loginRegInput"
                        multiple={false}
                        maxLength={1}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
        <Row>
          <Col lg={6} md={6} xs={12}>
            <TextField
              label="Plan Name"
              name="planName"
              select
              value={formData.planName}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={errors.planName ? true : false}
              helperText={errors.planName}
              style={{ display: operation === "Add User" ? "block" : "none" }}
            >
              {planNames.map((name, index) => (
                <MenuItem key={index} value={name}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <TextField
              label="Amount"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={errors.amount ? true : false}
              helperText={errors.amount}
              disabled={formData.amountDisabled}
              style={{ display: operation === "Add User" ? "block" : "none" }}
            />
          </Col>
        </Row>
        {operation === "Add User" ? (
          <>
            <Row>
              <Col lg={6} md={6} xs={12}>
                <TextField
                  label="Payment Mode"
                  name="paymentMode"
                  select
                  value={formData.paymentMode}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.paymentMode ? true : false}
                  helperText={errors.paymentMode}
                >
                  <MenuItem value="Online">Online</MenuItem>
                  <MenuItem value="DD">DD</MenuItem>
                  <MenuItem value="Cheque">Cheque</MenuItem>
                  <MenuItem value="NEFT">NEFT</MenuItem>
                </TextField>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <TextField
                  label="Transaction ID"
                  name="transactionId"
                  value={formData.transactionId}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Col>
            </Row>
            <Row>
              <Row>
                <Col
                  style={{ marginTop: "10px" }}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <Label style={{ fontWeight: 800 }}>Gender</Label>
                  <RadioGroup
                    aria-label="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    style={{ flexDirection: "row" }}
                    error={errors.gender ? true : false}
                  >
                    <FormControlLabel
                      value="Male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="Female"
                      control={<Radio />}
                      label="Female"
                    />
                  </RadioGroup>
                  {errors.gender && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.gender}
                    </div>
                  )}
                </Col>
                <Col
                  style={{ marginTop: "10px" }}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <Label style={{ fontWeight: 800 }}>Status</Label>
                  <RadioGroup
                    aria-label="status"
                    name="status"
                    style={{ flexDirection: "row" }}
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Active"
                      control={<Radio />}
                      label="Active"
                    />
                    <FormControlLabel
                      value="Deactive"
                      control={<Radio />}
                      label="Deactive"
                    />
                  </RadioGroup>
                  {errors.status && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.status}
                    </div>
                  )}
                </Col>
              </Row>
            </Row>
          </>
        ) : (
          <Row>
            <Col style={{ marginTop: "10px" }} lg={6} md={6} sm={6} xs={12}>
              <Label style={{ fontWeight: 800 }}>Gender</Label>
              <RadioGroup
                aria-label="gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                style={{ flexDirection: "row" }}
                error={errors.gender ? true : false}
              >
                <FormControlLabel
                  value="Male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="Female"
                  control={<Radio />}
                  label="Female"
                />
              </RadioGroup>
              {errors.gender && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.gender}
                </div>
              )}
            </Col>
            <Col style={{ marginTop: "10px" }} lg={6} md={6} sm={6} xs={12}>
              <Label style={{ fontWeight: 800 }}>Status</Label>
              <RadioGroup
                aria-label="status"
                name="status"
                style={{ flexDirection: "row" }}
                value={formData.status}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Active"
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value="Deactive"
                  control={<Radio />}
                  label="Deactive"
                />
              </RadioGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={6} md={6} xs={12}>
            {operation === "Add User" || (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.changePassword}
                    onClick={handleTogglePasswordChange}
                    color="primary"
                  />
                }
                label="Change Passowrd"
              />
            )}
          </Col>
          {operation === "Edit User" && formData.changePassword ? (
            <Col lg={6} md={6} xs={12}>
              {formData.changePassword && (
                <TextField
                  label="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={errors.password ? true : false}
                  helperText={errors.password || ""}
                />
              )}
            </Col>
          ) : (
            ""
          )}
        </Row>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <Button
            variant="contained"
            className="commongradient addbutton submitButton"
            onClick={handleSubmit}
            style={{ padding: "10px 20px", marginLeft: 10 }}
          >
            <span>Submit</span>
          </Button>
          <Button
            variant="contained"
            className="commongradient addbutton submitButton"
            onClick={handleClose}
            style={{ padding: "10px 20px", marginLeft: 10 }}
          >
            <span>Cancel</span>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AddUserDetail
