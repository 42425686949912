export function ApiPostMethod(url, data, method = "POST") {
  return fetch(url, {
    method: method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then(resp => resp.json())
    .then(function (result) {
      return result
    })
}

export function ApiGetMethod(url, data, method = "GET") {
  return fetch(url, {
    method: method,
    headers: { "Content-Type": "application/json" },
  })
    .then(resp => resp.json())
    .then(function (result) {
      return result
    })
}
