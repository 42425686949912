import React, { useEffect, useState, useRef } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
  ButtonGroup,
} from "reactstrap"

import classnames from "classnames"
import { Link, useLocation, useNavigate } from "react-router-dom"

//Import Breadcrumb
import RentBasicDetailsEdit from "../ResidentialTabEdit/RentTabs/Edit-basicDetails"
import RentaddressdetailsEdit from "../ResidentialTabEdit/RentTabs/Edit-addressdetails"
import RentadvanceDEdit from "../ResidentialTabEdit/RentTabs/Edit-advancedetails"
import RentamenitiesdetailsEdit from "../ResidentialTabEdit/RentTabs/Edit-amenitiesdetails"
import Photouploadcomm from "../PhotoUpload/Photoupload"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import image from "../../../assets/images/loader.gif"
import Modal from "react-bootstrap/Modal"
import "bootstrap/dist/css/bootstrap.min.css"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import slugify from "slugify"
import ImageUpload from "../PropertyImage/propImageUpload"
import Select from "react-select"
import PropertyDetailsEdit from "../ResidentialTabEdit/pgTabs/Edit-propertyDetails"
import PGRoomDetailsEdit from "../ResidentialTabEdit/pgTabs/Edit-roomDetails"
import OtherDetailsEdit from "../ResidentialTabEdit/pgTabs/Edit-otherDetails"
import CommBasicEdit from "../commercialEdits/basicDetailsEdit"
import CommPropDetailsEdit from "../commercialEdits/commPropDetailsEdit"
import CommAmminitiesEdit from "../commercialEdits/commAmminitiesEdit"
import { ApiGetMethod } from "helpers/withoutToken_helper"

const EditWizard = () => {
  const optionGroup = [
    {
      label: "Search City",
      options: [{ label: "Nagpur", value: "Nagpur" }],
    },
  ]
    const [cityGroup, setCityGroup] = useState([])

      const fetchLocData = async () => {
      try {
        const postUrl = `${HOST_URL}api/viewLocalities`

        const response = await ApiGetMethod(postUrl)
        const cities = response.map(cities => ({label:cities.city,value:cities._id}))
        setCityGroup(cities)
      } catch (error) {
        console.error("Error:", error)
      }
    }
        useEffect(() => {
          fetchLocData()
        }, [])
  const token = JSON.parse(localStorage.getItem("token"))
  const user_id = JSON.parse(localStorage.getItem("authUser"))
  const user_role = JSON.parse(localStorage.getItem("userRole"))
  const location = useLocation()
  const property_id = new URLSearchParams(location.search).get("propertyId")
  if (property_id) {
    localStorage.setItem("property_id", JSON.stringify(property_id))
  }
  const [propertyData, setPropertyData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [propertyType, setPropertyType] = useState()
  const [propertyType2, setPropertyType2] = useState()
  const [lookingFor, setLookingFor] = useState()
  const [isPostedBy, setIsPostedBy] = useState()
  const [searchCity, setSearchCity] = useState("")
  const [activeTab, setactiveTab] = useState(() => {
    const storedActiveTab = localStorage.getItem("activeTab")
    return storedActiveTab ? parseInt(storedActiveTab, 10) : 1
  })
  const [isSpotlight, setIsSpotlight] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    const url = `${HOST_URL}property/propertypreview`
    const propertyId = JSON.parse(localStorage.getItem("property_id"))
    const data = {
      user_id: user_id._id,
      property_id: propertyId || property_id,
    }
    ApiPostMethodWithToken(url, data, token).then(resp => {
      if (resp.error === false) {
        if (resp.property_detail.formStatus) {
          localStorage.setItem(
            "tabStatus",
            JSON.stringify(resp?.property_detail?.formStatus)
          )
          setTabStatus(resp?.property_detail?.formStatus)
          const pendingKey = Object.keys(resp.property_detail.formStatus).find(
            key => resp.property_detail.formStatus[key].status === "Pending"
          )
          if (pendingKey !== undefined) {
            setactiveTab(parseInt(pendingKey, 10))
            localStorage.setItem("activeTab", pendingKey)
          } else {
            const keys = Object.keys(resp.property_detail.formStatus)
            const lastKey = keys[keys.length - 1]
            setactiveTab(parseInt(lastKey, 10))
            localStorage.setItem("activeTab", lastKey)
          }
        }
        setPropertyData(resp.property_detail)
        // console.log("success-0-0-0", resp.property_detail)
        setIsLoading(false)
        setPropertyType(resp.property_detail.propertyDetails.propertyType1)
        setLookingFor(resp.property_detail.propertyDetails.lookingTo)
        setIsPostedBy(resp.property_detail.propertyDetails.postedBy)
        setPropertyType2(resp.property_detail.propertyDetails.propertyType2)
        if (resp.property_detail.propertyDetails.lookingTo == "PG/Co-living") {
          setSearchCity(resp.property_detail.propertyDetails.searchCity)
        }
        setIsSpotlight(resp?.property_detail?.isSpotlight)
      } else {
        // console.log("error-0-0-0-0", resp.message)
      }
    })
  }, [])

  const handlespotlight = async propId => {
    setIsSpotlight(prevState => !prevState)
    const url = `${HOST_URL}property/addToSpotlight`
    const payload = {
      property_id: propId,
      isSpotlight: !isSpotlight,
    }
    await ApiPostMethodWithToken(url, payload, token).then(resp => {
      if (resp.error === false) {
        toast.success(resp.message, {
          position: "top-right",
          theme: "light",
          autoClose: 1000,
        })
      } else {
        toast.error(resp.message, {
          position: "top-right",
          theme: "light",
          autoClose: 1000,
        })
      }
    })
  }

  const navigate = useNavigate()
  const RentBasicD = useRef()
  const RentAddresD = useRef()
  const RentAdvanceD = useRef()
  const RentAmenD = useRef()
  const CommercialBasicD = useRef()
  const CommercialPropD = useRef()
  const CommercialAmmD = useRef()
  const CommonPhoto = useRef()
  const PgPropD = useRef()
  const PgRoomD = useRef()
  const PgOtherD = useRef()
  const [activeTabProgress, setactiveTabProgress] = useState(1)
  const [progressValue, setprogressValue] = useState(20)

  const [Title, setTitle] = useState("")
  const [titleError, setTitleError] = useState("")
  // State variables
  const [tabStatus, setTabStatus] = useState(() => {
    const storedTabStatus = JSON.parse(localStorage.getItem("tabStatus"))
    if (storedTabStatus) {
      return storedTabStatus
    } else {
      let defaultTabStatus
      if (propertyType === "Residential" && lookingFor === "PG/Co-living") {
        defaultTabStatus = {
          1: { name: "Basic Details", status: "In Progress" },
          2: { name: "Property Details", status: "Pending" },
          3: { name: "Room Details", status: "Pending" },
          4: { name: "Amenities", status: "Pending" },
          5: { name: "Other details", status: "Pending" },
          6: { name: "Photos", status: "Pending" },
        }
      } else if(propertyType === "Residential" && lookingFor === "PG/Co-living" && propertyType2 === "Agricultural Land") {
        defaultTabStatus = {
          1: { name: "Basic Details", status: "In Progress" },
          2: { name: "Address", status: "Pending" },
          3: { name: "Photos", status: "Pending" },
          4: { name: "Advanced Details", status: "Pending" },
        }
      } else if (propertyType === "Commercial") {
        defaultTabStatus = {
          1: { name: "Basic Details", status: "In Progress" },
          2: { name: "Property Details", status: "Pending" },
          3: { name: "Amenities", status: "Pending" },
          4: { name: "Photos", status: "Pending" },
        }
      } else {
        defaultTabStatus = {
          1: { name: "Basic Details", status: "In Progress" },
          2: { name: "Address", status: "Pending" },
          3: { name: "Photos", status: "Pending" },
          4: { name: "Advanced Details", status: "Pending" },
          5: { name: "Amenities", status: "Pending" },
        }
      }
      return defaultTabStatus
    }
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [textcount, settextcount] = useState(0)
  const [rejectReason, setRejectReason] = useState("")
  const [rejectReasonError, setRejectReasonError] = useState("")
  const [status, setStatus] = useState("")
  const [slug, setSlug] = useState("")

  const [searchCityError, setSearchCityError] = useState("")

  useEffect(() => {
    let defaultTabStatus
    if (propertyType === "Residential" && lookingFor === "PG/Co-living") {
      defaultTabStatus = {
        1: { name: "Basic Details", status: "In Progress" },
        2: { name: "Property Details", status: "Pending" },
        3: { name: "Room Details", status: "Pending" },
        4: { name: "Amenities", status: "Pending" },
        5: { name: "Other details", status: "Pending" },
        6: { name: "Photos", status: "Pending" },
      }
    } else if(propertyType === "Residential" && lookingFor === "Sell" && propertyType2 === "Agricultural Land") {
        defaultTabStatus = {
          1: { name: "Basic Details", status: "In Progress" },
          2: { name: "Address", status: "Pending" },
          3: { name: "Photos", status: "Pending" },
          4: { name: "Advanced Details", status: "Pending" },
        }
    } else if (propertyType === "Commercial") {
      defaultTabStatus = {
        1: { name: "Basic Details", status: "In Progress" },
        2: { name: "Property Details", status: "Pending" },
        3: { name: "Amenities", status: "Pending" },
        4: { name: "Photos", status: "Pending" },
      }
    } else {
      defaultTabStatus = {
        1: { name: "Basic Details", status: "In Progress" },
        2: { name: "Address", status: "Pending" },
        3: { name: "Photos", status: "Pending" },
        4: { name: "Advanced Details", status: "Pending" },
        5: { name: "Amenities", status: "Pending" },
      }
    }

    setTabStatus(defaultTabStatus)
  }, [propertyType, lookingFor])

  const handleTabList = (Status) => {
    let storedTabStatus
    if(Status == "Agricultural Land"){
      storedTabStatus = {
        1: { name: "Basic Details", status: "In Progress" },
        2: { name: "Address", status: "Pending" },
        3: { name: "Photos", status: "Pending" },
        4: { name: "Advanced Details", status: "Pending" },
      }
    }else{
      if (propertyType === "Residential" && lookingFor === "PG/Co-living") {
        storedTabStatus = {
          1: { name: "Basic Details", status: "In Progress" },
          2: { name: "Property Details", status: "Pending" },
          3: { name: "Room Details", status: "Pending" },
          4: { name: "Amenities", status: "Pending" },
          5: { name: "Other details", status: "Pending" },
          6: { name: "Photos", status: "Pending" },
        }
      } else if (propertyType === "Commercial") {
        storedTabStatus = {
          1: { name: "Basic Details", status: "In Progress" },
          2: { name: "Property Details", status: "Pending" },
          3: { name: "Amenities", status: "Pending" },
          4: { name: "Photos", status: "Pending" },
        }
      } else {
        storedTabStatus = {
          1: { name: "Basic Details", status: "In Progress" },
          2: { name: "Address", status: "Pending" },
          3: { name: "Photos", status: "Pending" },
          4: { name: "Advanced Details", status: "Pending" },
          5: { name: "Amenities", status: "Pending" },
        }
      }
    }
    setTabStatus(storedTabStatus);
  }

  useEffect(() => {
    localStorage.setItem("tabStatus", JSON.stringify(tabStatus))
  }, [tabStatus])

  const handleModal = (status, title, slug) => {
    if (status == "Approved" && title && slug) {
      setSlug(slug)
      setTitle(title)
    } else {
      generateUniqueSlug()
    }
    setStatus(status)
    setIsModalOpen(true)
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
    setStatus("")
  }

  const [tabName, settabName] = useState()

  async function toggleTabNext(tab) {
    var formstatus = ""
    if (
      propertyType === "Residential" &&
      (lookingFor == "Rent" || lookingFor == "Sell") &&
      activeTab == 1
    ) {
      formstatus = await RentBasicD.current.handleRentBasic()
    } else if (
      propertyType === "Residential" &&
      (lookingFor == "Rent" || lookingFor == "Sell") &&
      activeTab == 2
    ) {
      formstatus = await RentAddresD.current.handleRentAdd()
    } else if (
      propertyType === "Residential" &&
      (lookingFor == "Rent" || lookingFor == "Sell") &&
      activeTab == 3
    ) {
      formstatus = await CommonPhoto.current.handleCimgupload()
    } else if (
      propertyType === "Residential" &&
      (lookingFor == "Rent" || lookingFor == "Sell") &&
      activeTab == 4
    ) {
      formstatus = await RentAdvanceD.current.handleRentadvanceD()
    } else if (
      propertyType === "Residential" &&
      (lookingFor == "Rent" || lookingFor == "Sell") &&
      activeTab == 5
    ) {
      formstatus = await RentAmenD.current.handleRentAmeD()
    } else if (
      propertyType == "Residential" &&
      lookingFor == "PG/Co-living" &&
      activeTab == 1
    ) {
      if (searchCity.trim() == "") {
        setSearchCityError("Search city is required")
        formstatus = { status: false, message: "Fill the required fields!.." }
      } else {
        const propertyId = JSON.parse(localStorage.getItem("property_id"))
        const url = `${HOST_URL}property/basicdetails`
        const data = {
          propertyDetails: {
            propertyType1: propertyType,
            lookingTo: lookingFor,
            searchCity: searchCity,
            postedBy: isPostedBy,
          },
          user_id: propertyData?.user_id,
          property_id: propertyId || property_id,
        }
        await ApiPostMethodWithToken(url, data, token).then(resp => {
          if (resp.error === false) {
            formstatus = { status: true, message: resp.message }
          } else {
            formstatus = { status: false, message: resp.message }
          }
        })
      }
    } else if (
      propertyType == "Residential" &&
      lookingFor == "PG/Co-living" &&
      activeTab == 2
    ) {
      formstatus = await PgPropD.current.handlePgPropD()
    } else if (
      propertyType == "Residential" &&
      lookingFor == "PG/Co-living" &&
      activeTab == 3
    ) {
      formstatus = await PgRoomD.current.handlePgRoomD()
    } else if (
      propertyType == "Residential" &&
      lookingFor == "PG/Co-living" &&
      activeTab == 4
    ) {
      formstatus = await RentAmenD.current.handleRentAmeD()
    } else if (
      propertyType == "Residential" &&
      lookingFor == "PG/Co-living" &&
      activeTab == 5
    ) {
      formstatus = await PgOtherD.current.handlePgOtherD()
    } else if (
      propertyType == "Commercial" &&
      (lookingFor == "Rent" || lookingFor == "Sell") &&
      activeTab == 1
    ) {
      formstatus = await CommercialBasicD.current.handleCommBasic()
    } else if (
      propertyType == "Commercial" &&
      (lookingFor == "Rent" || lookingFor == "Sell") &&
      activeTab == 2
    ) {
      formstatus = await CommercialPropD.current.handleCommProp()
    } else if (
      propertyType == "Commercial" &&
      (lookingFor == "Rent" || lookingFor == "Sell") &&
      activeTab == 3
    ) {
      formstatus = await CommercialAmmD.current.handleCommAmm()
    } else if (
      propertyType == "Commercial" &&
      (lookingFor == "Rent" || lookingFor == "Sell") &&
      activeTab == 4
    ) {
      formstatus = await CommonPhoto.current.handleCimgupload()
    }

    // console.log("formstatus", formstatus)
    if (formstatus instanceof Promise) {
      formstatus.then(function (result) {
        if (result.status === true) {
          if (activeTab !== tab) {
            if (tab >= 1 && tab <= Object.keys(tabStatus).length) {
              toast.success(result.message, {
                position: "top-right",
                theme: "light",
                autoClose: 1000,
              })
              setactiveTab(tab)
              toggleTabProgress(tab, Object.keys(tabStatus).length)
              setcomplpetedtab(tab)
            }
          }
        } else {
          toast.error(result.message, {
            position: "top-right",
            theme: "light",
            autoClose: 1000,
          })
        }
      })
    } else {
      if (formstatus.status === true) {
        if (activeTab !== tab) {
          if (tab >= 1 && tab <= Object.keys(tabStatus).length) {
            toast.success(formstatus.message, {
              position: "top-right",
              theme: "light",
              autoClose: 1000,
            })
            setactiveTab(tab)
            toggleTabProgress(tab, Object.keys(tabStatus).length)
            setcomplpetedtab(tab)
          }
        }
      } else {
        toast.error(formstatus.message, {
          position: "top-right",
          theme: "light",
          autoClose: 1000,
        })
      }
    }
  }
  function toggleTabPrev(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= Object.keys(tabStatus).length) {
        setactiveTab(tab)
        toggleTabProgress(tab, Object.keys(tabStatus).length)
      }
    }
  }
  function toggleTabSkip(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= Object.keys(tabStatus).length) {
        setactiveTab(tab)
      }
    }
  }

  useEffect(() => {
    const updatedTabStatus = {
      ...tabStatus,
      [activeTab]: {
        name: tabStatus[activeTab].name,
        status: getTabStatus(activeTab),
      },
    }

    if (activeTab > 1) {
      for (let i = 1; i < activeTab; i++) {
        updatedTabStatus[i] = { name: tabStatus[i].name, status: "Completed" }
      }
    }
    setTabStatus(updatedTabStatus)
    toggleTabProgress(activeTab, Object.keys(tabStatus).length)
    settabName(tabStatus[activeTab].name)
    // console.log("tabname", tabName)
  }, [activeTab])

  function getTabStatus(tab) {
    const storedActiveTab = localStorage.getItem("activeTab")
    if (tab < parseInt(storedActiveTab, 10)) {
      return "Completed"
    } else if (tab === activeTab) {
      return "In Progress"
    } else {
      return "Pending"
    }
  }
  function setcomplpetedtab(tab) {
    const storedActiveTab = localStorage.getItem("activeTab")
    if (tab > parseInt(storedActiveTab, 10)) {
      localStorage.setItem("activeTab", tab)
    } else if (storedActiveTab === null || storedActiveTab == "") {
      localStorage.setItem("activeTab", tab)
    }
  }
  function toggleTabProgress(tab, totalLength) {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= totalLength) {
        const progressStep = 100 / (totalLength - 1)
        const progressValue = Math.round((tab - 1) * progressStep)
        console.log("progressValue", progressValue)
        setactiveTabProgress(tab)

        setprogressValue(progressValue)
        // setactiveTabProgress(tab)

        // if (tab === 1) {
        //   setprogressValue(0)
        // }
        // if (tab === 2) {
        //   setprogressValue(20)
        // }
        // if (tab === 3) {
        //   setprogressValue(40)
        // }
        // if (tab === 4) {
        //   setprogressValue(60)
        // }
      }
    }
  }

  const handlePropertyTypeChange = value => {
    setPropertyType(value)
  }

  const handleListingTypeChange = value => {
    setLookingFor(value)
    if(value == 'PG/Co-living'){
      fetchLocData()
    }
  }

  const handlepostedBy = val => {
    setIsPostedBy(val)
  }

  const [cityId, setCityId] = useState()

  function handleSelectGroup(selectedGroup) {
    setSearchCity(selectedGroup.label)
    setCityId(selectedGroup.value)
    setSearchCityError("")
  }
  // console.log("tabStatus", tabStatus.length)

  const submithandle = async () => {
    var formstatus = ""
    if (
      propertyType == "Residential" &&
      (lookingFor == "Rent" || lookingFor == "Sell") &&
      activeTab == 5
    ) {
      formstatus = await RentAmenD.current.handleRentAmeD()
    } else if (lookingFor == "PG/Co-living" && activeTab == 6) {
      formstatus = await CommonPhoto.current.handleCimgupload()
    } else if (propertyType == "Commercial" && activeTab == 4) {
      formstatus = await CommonPhoto.current.handleCimgupload()
    }else if(propertyType == "Residential" && lookingFor == "Sell" && activeTab == 4){
      formstatus = await RentAdvanceD.current.handleRentadvanceD()
    }
    // console.log("formstatus", formstatus)
    if (formstatus instanceof Promise) {
      formstatus.then(function (result) {
        if (result.status === true) {
          const property_id = JSON.parse(localStorage.getItem("property_id"))
          if (property_id != null) {
            localStorage.removeItem("property_id")
          }
          toast.success(result.message, {
            position: "top-right",
            theme: "light",
            autoClose: 1000,
            onClose: () => {
              localStorage.setItem("activeTab", 1)
              setactiveTab(1)
              localStorage.removeItem("tabStatus")
              localStorage.removeItem("lookingFor")
              localStorage.removeItem("totalBeds")
              navigate(`/listing-status?property_id=${propertyData?._id}`)
            },
          })
        } else {
          toast.error(result.message, {
            position: "top-right",
            theme: "light",
            autoClose: 1000,
          })
        }
      })
    } else {
      if (formstatus.status === true) {
        const property_id = JSON.parse(localStorage.getItem("property_id"))
        if (property_id != null) {
          localStorage.removeItem("property_id")
        }
        // navigate('/propertyList');
        toast.success(formstatus.message + " Redirecting to listing page", {
          position: "top-right",
          theme: "light",
          autoClose: 1000,
          onClose: () => {
            localStorage.setItem("activeTab", 1)
            setactiveTab(1)
            localStorage.removeItem("tabStatus")
            localStorage.removeItem("lookingFor")
            localStorage.removeItem("totalBeds")
            navigate(`/listing-status?property_id=${propertyData?._id}`)
          },
        })
      } else {
        toast.error(formstatus.message, {
          position: "top-right",
          theme: "light",
          autoClose: 1000,
        })
      }
    }
  }

  const handleStatus = async (status, reason) => {
    const url = `${HOST_URL}property/propRejAppr`
    const propertyId = JSON.parse(localStorage.getItem("property_id"))
    var data
    if (status == "Reject") {
      if (reason == "") {
        setRejectReasonError("please enter reason for rejection!.")
        return
      } else {
        data = {
          property_id: propertyId || property_id,
          status: status,
          rejectReason: reason,
        }
      }
    } else {
      if (status == "Approved") {
        if (reason == "") {
          setTitleError("Please enter title for property!.")
          return
        } else {
          var exists = await checkSlugExistence(reason)
          // console.log("exo", exists)
          if (exists === false) {
            setTitleError("")
            data = {
              property_id: propertyId || property_id,
              status: status,
              rejectReason: reason,
              slug: slug,
            }
          } else {
            setTitleError("Title already exists!.")
          }
        }
      }
    }
    ApiPostMethodWithToken(url, data, token).then(resp => {
      if (resp.error === false) {
        // console.log(resp)
        localStorage.removeItem("tabStatus")
        localStorage.removeItem("property_id")
        localStorage.removeItem("lookingFor")
        localStorage.removeItem("totalBeds")
        localStorage.setItem("activeTab", 1)
        setRejectReasonError("")
        setRejectReason("")
        setTitleError("")
        settextcount(0)
        toggleModal()
        toast.success(`${resp.message}`, {
          position: "top-right",
          theme: "light",
          onClose: () => {
            navigate("/propertyList")
          },
        })
      } else {
        toast.error(`${resp.message}`, {
          position: "top-right",
          theme: "light",
        })
        // console.log(resp)
      }
    })
  }

  function textareachange(event) {
    settextcount(event.target.value.length)
    setRejectReason(event.target.value)
  }
  document.title = "Freelife Housing"

  const generateUniqueSlug = async () => {
    var baseSlug
    var additionalValues = []
    if (
      propertyData.propertyDetails.propertyType1 == "Residential" &&
      (propertyData.propertyDetails.lookingTo == "Rent" ||
        propertyData.propertyDetails.lookingTo == "Sell")
    ) {
      baseSlug = `${propertyData.propertyDetails.bhk} ${
        propertyData.propertyDetails.builtUpArea ||
        propertyData.propertyDetails.plotArea
      }Sq.ft ${propertyData.propertyDetails.propertyType2} for ${
        propertyData.propertyDetails.lookingTo
      } ${propertyData.propertyDetails.furnishType}`

      additionalValues = [
        propertyData.address.locality,
        propertyData.propertyDetails.searchCity ||
          propertyData.address.searchCity,
      ]
    } else if (
      propertyData.propertyDetails.propertyType1 == "Residential" &&
      propertyData.propertyDetails.lookingTo == "PG/Co-living"
    ) {
      baseSlug = `${
        propertyData.pgPropDetails.pgdetails.totalBeds
      } Beds ${propertyData.propertyDetails.lookingTo.replace("/", "-")} for ${
        propertyData.pgPropDetails.pgdetails.pgIsFor
      } prefer ${propertyData.pgPropDetails.pgdetails.bestSuitedFor}`
      additionalValues = [
        propertyData.address.locality,
        propertyData.propertyDetails.searchCity ||
          propertyData.address.searchCity,
      ]
    } else if (
      propertyData.propertyDetails.propertyType1 == "Commercial" &&
      (propertyData.propertyDetails.lookingTo == "Rent" ||
        propertyData.propertyDetails.lookingTo == "Sell")
    ) {
      baseSlug = `${
        propertyData.commPropDetails.builtUpArea ||
        propertyData.commPropDetails.plotArea
      }Sq.ft ${
        propertyData.commPropDetails.zoneType ||
        propertyData.commPropDetails.suitableFor
      } ${propertyData.commPropDetails.ownership} ${
        propertyData.propertyDetails.propertyType2
      } ${propertyData.propertyDetails.lookingTo}`
      additionalValues = [
        propertyData.propertyDetails.propertyType1,
        propertyData.address.locality,
        propertyData.propertyDetails.searchCity ||
          propertyData.address.searchCity,
      ]
    }

    let index = 0
    let uniqueSlug = slugify(baseSlug, { lower: true })

    var isSlugUnique = await checkSlugExistence(uniqueSlug)

    while (isSlugUnique) {
      baseSlug = `${baseSlug} ${additionalValues.slice(0, index + 1).join(" ")}`
      uniqueSlug = slugify(baseSlug, { lower: true })
      index++

      isSlugUnique = await checkSlugExistence(uniqueSlug)
    }
    setSlug(uniqueSlug)
    setTitle(baseSlug)
  }

  const checkSlugExistence = async slugToCheck => {
    var slugstatus
    // console.log("slugtocheck", slugToCheck)
    const response = await fetch(`${HOST_URL}api/checkSlug/${slugToCheck}`, {
      method: "post",
    })
    const data = await response.json()
    if (data.error === false) {
      slugstatus = false
    } else {
      slugstatus = true
    }
    return slugstatus
  }

  const postedBy = [
    { id: "1", value: "Agent" },
    { id: "2", value: "Broker" },
    { id: "3", value: "Owner" },
  ]
  if (user_role === "admin" || user_role === "manager") {
    postedBy.push({
      id: "4",
      value: "Freelife Housing",
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container className="p-0 position-relative">
          {isLoading ? (
            <Row className="loaderClass">
              <img src={image} alt="" style={{ width: "100px" }} />
            </Row>
          ) : (
            <Row>
              <Col lg="4" md="12" xs="12" className="p-2">
                <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                  <div className="steps clearfix border-right-top">
                    <div className="progressbar p-2">
                      <h5>Post your property</h5>
                      <h6 style={{ fontWeight: 300 }}>
                        Sell or rent your property
                      </h6>
                      <div className="d-flex justify-content-center align-items-center">
                        <Progress
                          color="primary"
                          value={progressValue}
                          className="w-100"
                        />{" "}
                        <span>{progressValue}%</span>
                      </div>
                    </div>
                    <ul className="listTabs">
                      {Object.keys(tabStatus).map(tab => (
                        <NavItem
                          key={tab}
                          className={classnames({
                            current: activeTab === Number(tab),
                          })}
                        >
                          <NavLink
                            className={classnames({
                              current: activeTab === Number(tab),
                            })}
                          >
                            <span
                              className={
                                tabStatus[tab].status == "Completed"
                                  ? "number completetab"
                                  : tabStatus[tab].status == "In Progress"
                                  ? "number activetab"
                                  : "number"
                              }
                            >
                              {tabStatus[tab].status == "Completed" ? (
                                <i className="ti-check"></i>
                              ) : (
                                ""
                              )}
                            </span>
                            <div className="steptext w-100">
                              <p className="tabstatus p-0 m-0">
                                {tabStatus[tab].name}
                              </p>
                              <span
                                className={
                                  tabStatus[tab].status == "Completed"
                                    ? "completestatus"
                                    : tabStatus[tab].status == "In Progress"
                                    ? "activestatus"
                                    : ""
                                }
                              >
                                {tabStatus[tab].status}
                              </span>
                            </div>
                          </NavLink>
                        </NavItem>
                      ))}
                    </ul>
                  </div>
                </div>
                <Col lg={12} className="d-flex justify-content-between mt-2">
                  {propertyData.status == "Approved" && user_role == "admin" ? (
                    <React.Fragment>
                      <Button
                        className="btnReject"
                        onClick={() => handleModal("Reject")}
                      >
                        Reject
                      </Button>
                      <Button
                        className={
                          isSpotlight ? "btnActivepostedby" : "btnActive"
                        }
                        onClick={() => handlespotlight(propertyData._id)}
                      >
                        {isSpotlight
                          ? "Spotlight Property"
                          : "Add to Spotlight"}
                      </Button>
                    </React.Fragment>
                  ) : user_role == "admin" ? (
                    <>
                      <Button
                        className="btnReject"
                        onClick={() => handleModal("Reject")}
                      >
                        Reject
                      </Button>
                      <Button
                        className="btnApprove"
                        onClick={() => {
                          if (propertyData.slug) {
                            handleModal(
                              "Approved",
                              propertyData.propTitle,
                              propertyData.slug
                            )
                            // handleStatus("Approved", propertyData.propTitle)
                          } else {
                            handleModal("Approved")
                          }
                        }}
                      >
                        Approve
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                </Col>
                {propertyData.status == "Reject" && user_role == "admin" ? (
                  <Col lg={12} className="d-flex justify-content-between mt-2">
                    <span>
                      <b>Rejet Reason : </b>
                      {propertyData && propertyData.rejectReason}
                    </span>
                  </Col>
                ) : (
                  ""
                )}
                <Modal show={isModalOpen} onHide={toggleModal} centered>
                  <Modal.Header closeButton>
                    {status == "Reject" ? (
                      <Modal.Title>Reject Reason</Modal.Title>
                    ) : (
                      <Modal.Title>Property Title</Modal.Title>
                    )}
                  </Modal.Header>
                  <Modal.Body>
                    {status == "Reject" ? (
                      <Row>
                        <Col md={12} className="text-end">
                          <Row className="mb-3 d-block">
                            <div className="col-lg-12">
                              <Input
                                type="textarea"
                                id="textarea"
                                className="btnNormal"
                                name="rejectReason"
                                onChange={e => {
                                  textareachange(e)
                                }}
                                maxLength="2000"
                                rows="3"
                                placeholder="Please enter the reason for rejection."
                                value={rejectReason}
                              />
                              <div className="d-flex justify-content-between">
                                <span className="error">
                                  {rejectReasonError && rejectReasonError}
                                </span>
                                <span className="badgecount d-block badge-success text-end">
                                  {" "}
                                  {textcount} / 2000{" "}
                                </span>
                              </div>
                            </div>
                          </Row>
                          <Button
                            className="commongradient addbutton"
                            onClick={() => handleStatus("Reject", rejectReason)}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col lg={12} className="text-end">
                          <Row className="mb-3 d-block">
                            <div className="col-lg-12">
                              <Input
                                type="text"
                                id="propertyTitle"
                                className="btnNormal"
                                name="propertyTitle"
                                onChange={e => {
                                  setTitle(e.target.value)
                                }}
                                placeholder="Please enter the title for property!."
                                value={Title}
                              />
                              <span className="error d-block w-100 text-start">
                                {titleError && titleError}
                              </span>
                            </div>
                          </Row>
                          <Button
                            className="commongradient addbutton"
                            onClick={() => handleStatus("Approved", Title)}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Modal.Body>
                </Modal>
              </Col>
              <Col lg="8" md="12" xs="12" className="p-0 pt-2 pb-2">
                <Card>
                  <CardBody>
                    <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body p-0">
                          <TabPane tabId={1}>
                            <h5>Add Basic Details</h5>
                            <Form>
                              <Row>
                                <Col md={12}>
                                  <Row className="mb-3 d-block">
                                    <label
                                      htmlFor="txtFirstNameBilling"
                                      className="col-lg-12 col-form-label font-weight-bold"
                                    >
                                      Property Posted By
                                    </label>
                                    <div className="col-lg-12">
                                      <ButtonGroup className="w-100">
                                        <div>
                                          {postedBy.map(property => (
                                            <Button
                                              style={{
                                                width: "190px",
                                                marginRight: "5px",
                                                marginBottom: "5px",
                                                padding: "10px",
                                                boxSizing: "border-box",
                                              }}
                                              key={property.id}
                                              className={
                                                isPostedBy === property.value
                                                  ? "btnActivepostedby custom-button customProp"
                                                  : "btnNormal custom-button customProp"
                                              }
                                              onClick={() =>
                                                handlepostedBy(property.value)
                                              }
                                            >
                                              <i
                                                className={property.icon}
                                                style={{ marginRight: "5px" }}
                                              ></i>
                                              {property.value}
                                            </Button>
                                          ))}
                                        </div>
                                      </ButtonGroup>
                                    </div>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <Row className="mb-3 d-block">
                                    <label
                                      htmlFor="txtFirstNameBilling"
                                      className="col-lg-12 col-form-label font-weight-bold"
                                    >
                                      Property Type
                                    </label>
                                    <div className="col-lg-12">
                                      <ButtonGroup className="w-100">
                                        <Button
                                          style={{
                                            marginRight: "5px",
                                            borderRadius: "5px",
                                          }}
                                          className={
                                            propertyType === "Residential"
                                              ? "btnActive"
                                              : "btnNormal"
                                          }
                                          onClick={() =>
                                            handlePropertyTypeChange(
                                              "Residential"
                                            )
                                          }
                                          disabled
                                        >
                                          Residential
                                        </Button>
                                        <Button
                                          style={{
                                            marginRight: "5px",
                                            borderRadius: "5px",
                                          }}
                                          className={
                                            propertyType === "Commercial"
                                              ? "btnActive"
                                              : "btnNormal"
                                          }
                                          onClick={() =>
                                            handlePropertyTypeChange(
                                              "Commercial"
                                            )
                                          }
                                          disabled
                                        >
                                          Commercial
                                        </Button>
                                      </ButtonGroup>
                                    </div>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <Row className="mb-3 d-block">
                                    <label
                                      htmlFor="txtFirstNameBilling"
                                      className="col-lg-12 col-form-label font-weight-bold"
                                    >
                                      Looking For
                                    </label>
                                    <div className="col-lg-12">
                                      <ButtonGroup className="w-100">
                                        <Button
                                          style={{
                                            marginRight: "5px",
                                            borderRadius: "5px",
                                          }}
                                          className={
                                            lookingFor == "Rent"
                                              ? "btnActive"
                                              : "btnNormal"
                                          }
                                          onClick={() =>
                                            handleListingTypeChange("Rent")
                                          }
                                          disabled
                                        >
                                          Rent
                                        </Button>
                                        <Button
                                          style={{
                                            marginRight: "5px",
                                            borderRadius: "5px",
                                          }}
                                          className={
                                            lookingFor == "Sell"
                                              ? "btnActive"
                                              : "btnNormal"
                                          }
                                          onClick={() =>
                                            handleListingTypeChange("Sell")
                                          }
                                          disabled
                                        >
                                          Sell
                                        </Button>
                                        {propertyType == "Residential" && (
                                          <Button
                                            style={{ borderRadius: "5px" }}
                                            className={
                                              lookingFor == "PG/Co-living"
                                                ? "btnActive"
                                                : "btnNormal"
                                            }
                                            onClick={() =>
                                              handleListingTypeChange(
                                                "PG/Co-living"
                                              )
                                            }
                                            disabled
                                          >
                                            PG/Co-living
                                          </Button>
                                        )}
                                      </ButtonGroup>
                                    </div>
                                  </Row>
                                </Col>
                              </Row>
                              {propertyType === "Residential" &&
                              (lookingFor == "Rent" || lookingFor == "Sell") ? (
                                <RentBasicDetailsEdit
                                  propertytype={propertyType}
                                  lookingfor={lookingFor}
                                  postedBy={isPostedBy}
                                  propData={propertyData && propertyData}
                                  propertyId={propertyData && propertyData._id}
                                  ref={RentBasicD}
                                  handleTabStatus={handleTabList}
                                />
                              ) : propertyType === "Commercial" &&
                                (lookingFor == "Rent" ||
                                  lookingFor == "Sell") ? (
                                <CommBasicEdit
                                  propertytype={propertyType}
                                  propData={propertyData && propertyData}
                                  propertyId={propertyData && propertyData._id}
                                  postedBy={isPostedBy}
                                  lookingfor={lookingFor}
                                  ref={CommercialBasicD}
                                />
                              ) : (
                                <Row>
                                  <Col md={12}>
                                    <Row className="mb-3 d-block">
                                      <label
                                        htmlFor="searchCity"
                                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                                      >
                                        Search City
                                      </label>
                                      <div className="col-lg-12">
                                        <Select
                                          id="searchCity"
                                          name="searchCity"
                                          value={{
                                            label: searchCity,
                                            value: searchCity,
                                          }}
                                          // defaultValue={[searchCity]}
                                          onChange={selectedValue => {
                                            handleSelectGroup(selectedValue)
                                          }}
                                          options={cityGroup}
                                          classNamePrefix="select2-selection"
                                        />
                                        {searchCityError && (
                                          <span className="error">
                                            {searchCityError}
                                          </span>
                                        )}
                                      </div>
                                    </Row>
                                  </Col>
                                </Row>
                              )}
                            </Form>
                          </TabPane>
                          <TabPane tabId={2}>
                            {propertyType === "Residential" &&
                            (lookingFor == "Rent" || lookingFor == "Sell") ? (
                              <RentaddressdetailsEdit
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData && propertyData}
                                propertyId={propertyData && propertyData._id}
                                ref={RentAddresD}
                              />
                            ) : propertyType === "Commercial" &&
                              (lookingFor == "Rent" || lookingFor == "Sell") ? (
                              <CommPropDetailsEdit
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData && propertyData}
                                propertyId={propertyData && propertyData._id}
                                ref={CommercialPropD}
                              />
                            ) : (
                              <PropertyDetailsEdit
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData && propertyData}
                                cityId={cityId}
                                propertyId={propertyData && propertyData._id}
                                ref={PgPropD}
                              />
                            )}
                          </TabPane>
                          <TabPane tabId={3}>
                            {propertyType === "Residential" &&
                            (lookingFor == "Rent" || lookingFor == "Sell") ? (
                              <Photouploadcomm
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                tabname={tabName}
                                propData={propertyData && propertyData}
                                propertyId={propertyData && propertyData._id}
                                toggleTabNext={toggleTabSkip}
                                ref={CommonPhoto}
                              />
                            ) : propertyType === "Commercial" &&
                              (lookingFor == "Rent" || lookingFor == "Sell") ? (
                              <CommAmminitiesEdit
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData}
                                propertyId={propertyData && propertyData._id}
                                ref={CommercialAmmD}
                              />
                            ) : (
                              <PGRoomDetailsEdit
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData && propertyData}
                                propertyId={propertyData && propertyData._id}
                                ref={PgRoomD}
                              />
                            )}
                          </TabPane>
                          <TabPane tabId={4}>
                            {propertyType === "Residential" &&
                            (lookingFor == "Rent" || lookingFor == "Sell") ? (
                              <RentadvanceDEdit
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData && propertyData}
                                propertyId={propertyData && propertyData._id}
                                ref={RentAdvanceD}
                              />
                            ) : propertyType === "Commercial" &&
                              (lookingFor == "Rent" || lookingFor == "Sell") ? (
                              <Photouploadcomm
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                tabname={tabName}
                                propData={propertyData && propertyData}
                                propertyId={propertyData && propertyData._id}
                                toggleTabNext={toggleTabSkip}
                                ref={CommonPhoto}
                              />
                            ) : (
                              <RentamenitiesdetailsEdit
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData}
                                propertyId={propertyData && propertyData._id}
                                ref={RentAmenD}
                              />
                            )}
                          </TabPane>
                          <TabPane tabId={5}>
                            {lookingFor == "Rent" || lookingFor == "Sell" ? (
                              <RentamenitiesdetailsEdit
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData}
                                propertyId={propertyData && propertyData._id}
                                ref={RentAmenD}
                              />
                            ) : (
                              <OtherDetailsEdit
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                propData={propertyData && propertyData}
                                propertyId={propertyData && propertyData._id}
                                ref={PgOtherD}
                              />
                            )}
                          </TabPane>
                          {propertyType == "Residential" &&
                          lookingFor == "PG/Co-living" ? (
                            <TabPane tabId={6}>
                              <Photouploadcomm
                                propertytype={propertyType}
                                lookingfor={lookingFor}
                                tabname={tabName}
                                propData={propertyData && propertyData}
                                propertyId={propertyData && propertyData._id}
                                toggleTabNext={toggleTabSkip}
                                ref={CommonPhoto}
                              />
                            </TabPane>
                          ) : (
                            ""
                          )}
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul style={{ display: "flex", alignItems: "center" }}>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Button
                              className="previousStep"
                              onClick={() => {
                                toggleTabPrev(activeTab - 1)
                              }}
                            >
                              Previous
                            </Button>
                          </li>
                          {activeTab === Object.keys(tabStatus).length ? (
                            <li
                              className={
                                activeTab === Object.keys(tabStatus).length
                                  ? "next"
                                  : "next"
                              }
                            >
                              <Link
                                to=""
                                className="commongradient"
                                onClick={() => {
                                  submithandle()
                                }}
                              >
                                Submit
                              </Link>
                            </li>
                          ) : (
                            <li
                              style={{ marginTop: "2px" }}
                              className={
                                activeTab === Object.keys(tabStatus).length
                                  ? "next"
                                  : "next"
                              }
                            >
                              <Link
                                to="#"
                                className="commongradient"
                                onClick={() => {
                                  toggleTabNext(activeTab + 1)
                                }}
                              >
                                Next
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default EditWizard
