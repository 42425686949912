import React, { useEffect, useRef, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  ListGroupItem,
  Row,
  TabPane,
} from "reactstrap"
import Modal from "react-bootstrap/Modal"
import MUIDataTable from "mui-datatables"
import { HOST_URL } from "helpers/url_helper"
import Swal from "sweetalert2"
import {
  Grid,
  Tooltip,
  Typography,
} from "@mui/material"
import AddLocalityCity from "./addLocalCity"
import { ApiGetMethod } from "helpers/withoutToken_helper"
import { toast, ToastContainer } from "react-toastify"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"

const Localities = () => {
    const [localityData, setLocalityData] = useState([]);
    const [localityList, setLocalityList] = useState([]);
    const [cLocality, setCLocality] = useState([]);
    const [isModalOpen2, setIsModalOpen2] = useState(false)
    const [showModel, setShowModel] = useState(false)
    const token = JSON.parse(localStorage.getItem("token"))
    const columns = [
        {
            name: "SN",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                return tableMeta.rowIndex + 1
                },
            },
        },
        {
            name: "_id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: "createdAt",
            label: "date",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: "state",
            label: "State",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "city",
            label: "City",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "localityCount",
            label: "Locality",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                return (
                    <span
                        style={{
                            cursor: "pointer",
                            display: "block",
                            width: "100%",
                        }}
                        onClick={() => handleLocalityClick(tableMeta)}
                    >
                        {value}
                    </span>
                )
                },
            },
        },
        {
            name: "actions",
            label: "ACTIONS",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const createdAt = new Date(tableMeta.rowData[2]);
                    const now = new Date();
                    const diffInHours = (now - createdAt) / (1000 * 60 * 60);
                    if (diffInHours <= 24) {
                        return (
                            <Tooltip title="Delete">
                                <button className="actionview" onClick={()=>handleDeleteCity(tableMeta.rowData[1])}>
                                    <i className="mdi mdi-delete-outline"></i>
                                </button>
                            </Tooltip>
                        );
                    } else {
                        return (
                                <button className="actionview" disabled>
                                    <i className="mdi mdi-delete-outline"></i>
                                </button>
                        );
                    }
                },
            },
        },
    ]

  const options = {
    filter: true,
    selectableRows: "none",
    responsive: "standard",
    // searchText: searchValue,
    download: false,
    viewColumns: false,
    print: false,
  }

    useEffect(()=>{
        fetchLocalities();
    },[])
    const fetchLocalities = async() => {
        try {
            const postUrl = `${HOST_URL}api/viewLocalities`
            const response = await ApiGetMethod(postUrl)
            const tableData = response.map(data => ({
                _id:data._id,
                state:data.state,
                city:data.city,
                localityCount:data.localities.length,
                createdAt:data.createdAt,
            }))
            setLocalityData(response)
            setLocalityList(tableData)
            console.log("loaclities form response---", response)
        } catch (error) {
            console.error("Error:", error)
        }
    }

    const handleDeleteCity = async(id) => {
        console.log("city_id", id);
        Swal.fire({
            title: "Are you sure want to delete?",
            text: "You will not be able to recover this data!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async result => {
            if (result.isConfirmed) {
                const url = `${HOST_URL}api/deleteLocality`
                const data = {locid: id,}

                try {
                    const response = await ApiPostMethodWithToken(url, data, token)
                    if (response.error === false) {
                        fetchLocalities();
                        // Swal.fire({
                        //     title:'Success',
                        //     text:response.message,
                        //     icon:'success',
                        // })
                        toast.success(response.message, {
                            position: "top-right",
                            theme: "light",
                        })
                    } else {
                        toast.error(response.message, {
                            position: "top-right",
                            theme: "light",
                        })
                    }
                } catch (error) {
                    toast.error("Error deleting user", {
                        position: "top-right",
                        theme: "light",
                    })
                }
            }
        })
    }

    const handleLocalityClick = row =>{
        const localities = localityData.find(locality => locality._id == row.rowData[1])
        if(localities){
            const data = localities.localities.map(loc => ({id:localities._id, name:loc}));
            setCLocality(data)
        }
        toggleModal2()
    }

    const toggleModal2 = () => {
        setIsModalOpen2(!isModalOpen2)
    }
    const handleModelOpen = () => {
        setShowModel(true)
    }
    const hideModel = () => {
        setShowModel(false)
    }
    return(
        <React.Fragment>
            <div className="page-content user-list">
                <Row>
                    <Col lg={12} className="pe-0 pt-2 pb-2">
                        <Card className="border-right-top">
                            <CardBody className="pt-3">
                                <Grid
                                    container
                                    display="flex"
                                    alignItems="center"
                                    justify="space-between"
                                >
                                    <Grid item xs={2}>
                                        <h5>Localities</h5>
                                    </Grid>
                                    <Grid item xs={10} container justifyContent="flex-end">
                                        <Link
                                            onClick={handleModelOpen}
                                            style={{ padding: "8px 36px", marginTop: "3px" }}
                                            className="commongradient addbutton addbtn"
                                        >
                                            Add Locality
                                        </Link>
                                    </Grid>
                                </Grid>
                                <TabPane tabId="2" style={{ margin: "0px 20px" }}>
                                    <Row>
                                        <Col sm="12" className="p-1 ps-0 pe-0">
                                            <MUIDataTable
                                            title=""
                                            data={localityList}
                                            columns={columns}
                                            options={options}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

            <Modal show={isModalOpen2} onHide={toggleModal2} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Locality List</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: "400px", overflowY: "auto" }}>
                    {cLocality && cLocality.length > 0 ? (
                        cLocality.map((locality,i) => (
                            <ListGroupItem key={i} className="mt-2 p-2" style={{borderRadius:'10px', border: '1px solid #c9c9c9'}}>
                                <Col lg={12} className="d-flex align-items-center justify-content-between">
                                    <Col lg={7} className="d-flex align-items-center">
                                        <i className="mdi mdi-map-marker-radius" style={{ fontSize: "25px" }}></i>
                                        <h5 className="m-0 ms-2">{locality.name}</h5>
                                    </Col>
                                    {/* <Col lg={1}>
                                        <Tooltip title="Delete">
                                            <button className="actionview">
                                                <i className="mdi mdi-delete-outline"></i>
                                            </button>
                                        </Tooltip>
                                    </Col> */}
                                </Col>
                            </ListGroupItem>
                        ))
                    ) : (
                        <Typography variant="body1">
                            Locality not Added
                        </Typography>
                    )}
                </Modal.Body>
            </Modal>
            <AddLocalityCity show={showModel} handleClose={hideModel} fetchlocality={fetchLocalities} />
            <ToastContainer />
        </React.Fragment>
    )
}

export default Localities