import React, { useState, useEffect } from "react"
import { Button, Col, Row } from "reactstrap"
import Select from "react-select"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import { ApiGetMethod } from "helpers/withoutToken_helper"

const CommBasicEdit = React.forwardRef(
  ({ propertytype, lookingfor, propData, propertyId, postedBy }, ref) => {
    // console.log("looking for---", lookingfor)
    // console.log("looking for ref---", ref)

    // search field

    const [searchCity, setSearchCity] = useState(
      propData?.propertyDetails?.searchCity || ""
    )
    const [searchCityError, setSearchCityError] = useState("")
    const optionGroup = [
      {
        label: "Search City",
        options: [{ label: "Nagpur", value: "Nagpur" }],
      },
    ]
    const [cityGroup, setCityGroup] = useState([])

    const fetchLocData = async () => {
      try {
        const postUrl = `${HOST_URL}api/viewLocalities`

        const response = await ApiGetMethod(postUrl)
        const cities = response.map(cities => ({label:cities.city,value:cities._id}))
        setCityGroup(cities)
      } catch (error) {
        console.error("Error:", error)
      }
    }
        useEffect(() => {
          fetchLocData()
        }, [])

    function handleSelectGroup(selectedGroup) {
      setSearchCity(selectedGroup.label)
      localStorage.setItem("cityId", selectedGroup.value)
      setSearchCityError("")
      setCommBasicDetails(prevDetails => ({
        ...prevDetails,
        searchCity: selectedGroup.label,
      }))
    }

    // property type field

    const [commPropertyType, setCommPropertyType] = useState(
      propData?.propertyDetails?.propertyType2 ||
        localStorage.getItem("PropertyType2") ||
        "Office"
    )

    useEffect(() => {
      localStorage.setItem("PropertyType2", commPropertyType)
    }, [commPropertyType])

    const CommProArray = [
      { id: 1, value: "Office", icon: "mdi mdi-city-variant-outline" },
      { id: 2, value: "Retail Shop", icon: "mdi mdi-home-floor-0" },
      { id: 3, value: "Showroom", icon: "mdi mdi-home-outline" },
      { id: 4, value: "Warehouse", icon: "mdi mdi-package-variant-closed" },
      { id: 5, value: "Plot", icon: "mdi mdi-chart-line" },
      { id: 6, value: "Other", icon: " mdi mdi-shape-outline" },
    ]

    useEffect(() => {
      setCommBasicDetails(prevDetails => ({
        ...prevDetails,
        propertyType1: propertytype,
        lookingTo: lookingfor,
        postedBy: postedBy,
      }))
    }, [propertytype, lookingfor])

    const handleCommPropType = val => {
      setCommPropertyType(val)
      setCommBasicDetails(prevDetails => ({
        ...prevDetails,
        propertyType2: val,
      }))
    }

    const user_id = JSON.parse(localStorage.getItem("authUser"))
    const user_role = JSON.parse(localStorage.getItem("userRole"))

    const [commBasicDetails, setCommBasicDetails] = useState({
      searchCity: propData?.propertyDetails?.searchCity || "", // Initialize searchCity as empty
      propertyType2: propData?.propertyDetails?.propertyType2 || "",
    })

    // console.log("commercial basic details edit-", commBasicDetails)

    const [commBasicError, setCommBasicError] = useState({
      searchCity: "",
      propertyType2: "",
    })

    const handleSubmit = async () => {
      // return true
      const newErrors = {}
      if (
        commBasicDetails.lookingTo === "Rent" ||
        commBasicDetails.lookingTo === "Sell"
      ) {
        if (commBasicDetails.searchCity.trim() === "") {
          newErrors.searchCity = "City is required"
        }
        if (commBasicDetails.propertyType2.trim() === "") {
          newErrors.propertyType2 = "Building type is required"
        }
      }
      setCommBasicError(newErrors)
      if (Object.values(newErrors).every(error => error === "")) {
        var status
        const property_id = JSON.parse(localStorage.getItem("property_id"))
        const token = JSON.parse(localStorage.getItem("token"))
        const url = `${HOST_URL}property/basicdetails`
        const data = {
          propertyDetails: commBasicDetails,
          user_id: propData?.user_id,
          property_id: propertyId || property_id,
        }
        var response
        await ApiPostMethodWithToken(url, data, token).then(resp => {
          if (resp.error === false) {
            localStorage.setItem(
              "property_id",
              JSON.stringify(resp.property_id)
            )
            localStorage.setItem("commpropertyType2", commPropertyType) // Update local storage with the new value

            status = true
            response = resp
          } else {
            status = false
            response = resp
          }
        })
        return { status: status, message: response.message }
      } else {
        // console.log("newErrors", newErrors)
        return { status: false, message: "Fill the required fields!.." }
      }
    }

    React.useImperativeHandle(ref, () => ({
      handleCommBasic: () => handleSubmit(),
    }))

    return (
      <>
        <Row>
          <Col md={12}>
            <Row className="mb-3 d-block">
              <label
                htmlFor="searchCity"
                className="col-lg-12 col-form-label requiredfield font-weight-bold"
              >
                Search City
              </label>
              <div className="col-lg-12">
                <Select
                  id="searchCity"
                  name="searchCity"
                  value={{ label: searchCity, value: searchCity }}
                  onChange={selectedValue => {
                    handleSelectGroup(selectedValue)
                  }}
                  options={cityGroup}
                  classNamePrefix="select2-selection"
                />
                {commBasicError.searchCity && (
                  <span className="error">{commBasicError.searchCity}</span>
                )}
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Row className="mb-3 d-block">
              <label
                htmlFor="txtFirstNameBilling"
                className="col-lg-12 col-form-label requiredfield font-weight-bold"
              >
                Building Type
              </label>
              <div className="col-lg-12 ">
                <div className="comm-button-group-container">
                  {CommProArray.map(property => (
                    <Button
                      key={property.id}
                      className={`proptype ${
                        commPropertyType === property.value
                          ? "btnActive custom-button"
                          : "btnNormal custom-button"
                      }`}
                      onClick={() => handleCommPropType(property.value)}
                    >
                      <i
                        className={property.icon}
                        style={{ marginRight: "5px" }}
                      ></i>
                      {property.value}
                    </Button>
                  ))}
                </div>
                {commBasicError.propertyType2 && (
                  <span className="error">{commBasicError.propertyType2}</span>
                )}
              </div>
            </Row>
          </Col>
        </Row>
      </>
    )
  }
)

export default CommBasicEdit
