import React, { useEffect, useState } from "react"
import classnames from "classnames"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  Nav,
  ListGroup,
  ListGroupItem,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import "../Tables/datatables.scss"
import { Link, useNavigate } from "react-router-dom"
import { HOST_URL, NEXT_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import MUIDataTable from "mui-datatables"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Modal from "react-bootstrap/Modal"
import "bootstrap/dist/css/bootstrap.min.css"
import DeleteModal from "./deletemodal"
import { TableCell, TableRow } from "@material-ui/core"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Swal from "sweetalert2"

const PropertyListUser = () => {
  const navigate = useNavigate()
  const token = JSON.parse(localStorage.getItem("token"))
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [allproperty, setAllProperty] = useState([])
  const [listMessage, setListMessage] = useState("")
  const [ttop, setttop] = useState(false)

  const formatDate = createdAt => {
    const userCreatedAt = new Date(createdAt)
    const formattedDate = userCreatedAt.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })

    return formattedDate
  }
  const fetchProperty = () => {
    const url = `${HOST_URL}property/userpropertylist`
    const data = {
      user_id: authUser._id,
    }
    ApiPostMethodWithToken(url, data, token)
      .then(data => {
        if (data.error === false) {
          // console.log(data)
          setAllProperty(data.propertyList)
        } else {
          setListMessage(data.message)
        }
      })
      .catch(error => console.log(`Error: ${error}`))
  }
  useEffect(() => {
    fetchProperty()
  }, [])

  const options = {
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        displayRows: "Of",
      },
    },
    selectableRows: "none",
    responsive: "vertical",
    download: false,
    viewColumns: false,
    filter: false,
    print: false,
    customRowRender: (data, dataIndex, rowIndex) => {
      // console.log("data", data)
      const filteredData = data.filter(
        (_, index) => columns[index].options.display !== false
      )
      const propExpired = data[2]
      const rowStyle =
        propExpired === true
          ? { backgroundColor: "#ff0000a1", color: "#fff" }
          : {}
      return (
        <TableRow style={rowStyle}>
          {filteredData.map((cellData, cellIndex) => (
            <TableCell key={cellIndex} style={{ padding: "10px" }}>
              {cellData}
            </TableCell>
          ))}
        </TableRow>
      )
    },
  }
  const columns = [
    {
      label: "SN",
      name: "sNo",
      options: {
        filter: false,
        sort: false,
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return tableMeta.rowIndex + 1
        // },
      },
    },
    {
      name: "property_id",
      options: {
        display: false,
      },
    },
    {
      name: "propExpired",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "slug",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "postedBy",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      label: "Property Type",
      name: "propertyType1",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Looking To",
      name: "lookingTo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Building Type",
      name: "propertyType2",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Price",
      name: "monthlyRent",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <span>{value}</span>
              {value == "Reject" ? (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {
                          allproperty.find(
                            p => p.property_id === tableMeta.rowData[1]
                          ).rejectReason
                        }
                      </Tooltip>
                    }
                  >
                    <i
                      className="mdi mdi-information-outline"
                      id="TooltipTop"
                    ></i>
                  </OverlayTrigger>
                </>
              ) : (
                ""
              )}
            </>
          )
        },
      },
    },
    {
      label: "Lead",
      name: "leadcount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Locality",
      name: "searchCity",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Action",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <React.Fragment>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-top">View</Tooltip>}
              >
                <button
                  className="actionview me-1"
                  onClick={() => handleView(tableMeta)}
                >
                  <i className="mdi mdi-eye-outline"></i>
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-top">Edit</Tooltip>}
              >
                <button
                  className="actionview me-1"
                  onClick={() => handleEdit(tableMeta)}
                >
                  <i className="mdi mdi-clipboard-edit-outline"></i>
                </button>
              </OverlayTrigger>
              {tableMeta.rowData[9] != "Deleted" && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}
                >
                  <button
                    className="actionview"
                    onClick={() => showDeleteModal(tableMeta)}
                  >
                    <i className="mdi mdi-delete-outline"></i>
                  </button>
                </OverlayTrigger>
              )}
            </React.Fragment>
          )
        },
      },
    },
  ]

  const handleView = row => {
    // window.location.href = `http://192.168.1.9:3000/singleProperty?property_id=${row.rowData[1]}`;
    const url = `${NEXT_URL}property?${row.rowData[3]}`
    window.open(url, "_blank")
    // console.log("View clicked for row:", row)
  }

  const handleEdit = row => {
    if (row.rowData[4] == "Freelife Housing") {
      Swal.fire({
        title: "Posted Property",
        text: "This property is posted by Freelife Housing. If you want to make changes contact Admin!",
        icon: "info",
        showCloseButton: true,
      })
    } else {
      navigate(`/listing-status?property_id=${row.rowData[1]}`)
    }
    // console.log("Edit clicked for row:", row)
  }

  const handleRoute = async () => {
    try {
      const url = `${HOST_URL}user/userActivePlan`
      const data = {
        user_id: authUser._id,
      }
      await ApiPostMethodWithToken(url, data, token).then(resp => {
        if (resp.error === false) {
          localStorage.setItem("activeTab", 1)
          localStorage.removeItem("tabStatus")
          localStorage.removeItem("property_id")
          localStorage.removeItem("totalBeds")
          navigate("/add-property")
        } else {
          toast.error(resp.message, {
            position: "top-right",
            theme: "light",
          })
        }
      })
    } catch (error) {
      toast.error(error, {
        position: "top-right",
        theme: "light",
      })
    }
  }

  const [showModel, setShowModel] = useState(false)
  const [deletePropId, setDeletePropId] = useState("")

  const showDeleteModal = row => {
    setDeletePropId(row.rowData[1])
    setShowModel(true)
  }

  const hideDeleteModel = () => {
    setShowModel(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true} className="p-0">
          <Row>
            <Col lg={12} className="pe-0 pt-2 pb-2">
              <Card className="border-right-top">
                <CardBody>
                  <Row className="page-title">
                    <Col lg={6}>
                      <h5>Property List</h5>
                    </Col>
                    <Col lg={6} className="text-end">
                      <Button
                        onClick={handleRoute}
                        className="commongradient addbutton"
                      >
                        Add Property
                      </Button>
                    </Col>
                    <Row>
                      <Col sm="12" className="p-1 ps-0 pe-0">
                        {listMessage ? (
                          <React.Fragment>
                            <h5 className="text-center mt-5">{listMessage}</h5>
                          </React.Fragment>
                        ) : (
                          <MUIDataTable
                            data={allproperty}
                            columns={columns}
                            options={options}
                          />
                        )}
                      </Col>
                    </Row>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <DeleteModal
        show={showModel}
        handleClose={hideDeleteModel}
        property_Id={deletePropId}
        ontoastclose={fetchProperty}
      />
      <ToastContainer />
    </React.Fragment>
  )
}

export default PropertyListUser
