import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
  ButtonGroup,
  FormFeedback,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"
import Modal from "react-bootstrap/Modal"
import "bootstrap/dist/css/bootstrap.min.css"
import Select from "react-select"

const RentBasicDetails = React.forwardRef(
  ({ propertytype, lookingfor, postedBy, propData, handleTabStatus }, ref) => {
    console.log("rent basic details", propData)
    // console.log("propertyType",propertytype);
    // console.log("lookingFor",lookingfor);
    const flatfurnish = [
      { id: 1, value: "Dining Table", icon: "" },
      { id: 2, value: "Washing Machine", icon: "" },
      { id: 3, value: "Sofa", icon: "" },
      { id: 4, value: "Microwave", icon: "" },
      { id: 5, value: "Stove", icon: "" },
      { id: 6, value: "Refrigerator", icon: "" },
      { id: 7, value: "Water Purifier", icon: "" },
      { id: 8, value: "Gas Pipeline", icon: "" },
      { id: 9, value: "AC", icon: "" },
      { id: 10, value: "Bed", icon: "" },
      { id: 11, value: "TV", icon: "" },
      { id: 12, value: "Cupboard", icon: "" },
      { id: 13, value: "Geyser", icon: "" },
    ]
    const Societyamen = [
      { id: 1, value: "Lift", icon: "" },
      { id: 2, value: "CCTV", icon: "" },
      { id: 3, value: "GYM", icon: "" },
      { id: 4, value: "Garden", icon: "" },
      { id: 5, value: "Kids Area", icon: "" },
      { id: 6, value: "Sports", icon: "" },
      { id: 7, value: "Swimming Pool", icon: "" },
      { id: 8, value: "Intercom", icon: "" },
      { id: 9, value: "Gated Community", icon: "" },
      { id: 10, value: "Club House", icon: "" },
      { id: 11, value: "Community Hall", icon: "" },
      { id: 12, value: "Regular Water Supply", icon: "" },
      { id: 13, value: "Power Backup", icon: "" },
      { id: 14, value: "Pet Allowed", icon: "" },
      { id: 15, value: "Security Guard", icon: "" },
      { id: 16, value: "Terrace Garden", icon: "" },
      { id: 17, value: "Regular Cleaning Staff", icon: "" },
    ]

    const bhkarray = [
      { id: 1, value: "1BHK" },
      { id: 2, value: "2BHK" },
      { id: 3, value: "3BHK" },
      { id: 4, value: "4BHK" },
      { id: 5, value: "5BHK" },
      { id: 6, value: "6BHK" },
      { id: 7, value: "7BHK" },
      { id: 8, value: "8BHK" },
      { id: 9, value: "9BHK" },
      { id: 10, value: "10BHK" },
    ]
    const bathroomarray = [
      { value: 0 },
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10 },
    ]

    const balcoveropenpararray = [
      { value: 0 },
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
    ]
    const tenanttypearray = [
      { id: "1", value: "Family" },
      { id: "2", value: "Bachelors" },
      { id: "3", value: "Company" },
      { id: "4", value: "Student-Girl" },
      { id: "5", value: "Student-Boy" },
      { id: "6", value: "Any" },
    ]
    const [bhkvalue, setBhkValue] = useState(
      propData?.propertyDetails?.bhk || ""
    )
    const [bathroomvalue, setBathroomValue] = useState()
    const [balconyvalue, setBalconyValue] = useState(
      parseInt(propData?.propertyDetails?.balcony) || ""
    )
    const [coverPvalue, setCoverPValue] = useState(
      parseInt(propData?.propertyDetails?.coveredParking) || ""
    )
    const [openPvalue, setOpenPValue] = useState(
      parseInt(propData?.propertyDetails?.openParking) || ""
    )
    const [furnishtype, setFurnishType] = useState(
      propData?.propertyDetails?.furnishType || ""
    )
    const [tenanTvalue, setTenanTValue] = useState(
      propData?.propertyDetails?.prefferedType || []
    )
    const [maintainanceCharges, setmaintainanceCharges] = useState(
      parseInt(propData?.propertyDetails?.maintainanceCharges) || ""
    )
    const [securityDeposit, setsecurityDeposit] = useState(
      propData?.propertyDetails?.securityDeposit || ""
    )
    const [lockInPeriod, setlockInPeriod] = useState(
      propData?.propertyDetails?.lockInPeriod || ""
    )
    const [brokerageCharge, setbrokerageCharge] = useState(
      propData?.propertyDetails?.brokerageCharge || ""
    )
    const [legalvalue, setlegalvalue] = useState()
    const [rentalvalue, setrentalvalue] = useState()
    const [SDDiscount, setSDDiscount] = useState()
    const [homeServices, sethomeServices] = useState()
    const [textcount, settextcount] = useState(0)
    const [propertyType2, setPropertyType2] = useState(
      propData?.propertyDetails?.propertyType2 ||
        localStorage.getItem("PropertyType2") ||
        "Apartment"
    )

    useEffect(() => {
      localStorage.setItem("PropertyType2", propertyType2)
    }, [propertyType2])

    const optionGroup = [
      {
        label: "Select Unit",
        options: [
          { label: "Square Millimetres", value: "Sq.mm" },
          { label: "Square Metres", value: "Sq.m" },
          { label: "Square Centimetres", value: "Sq.cm" },
          { label: "Square Kilometres", value: "Sq.km" },
          { label: "Square feet", value: "Sq.ft" },
          ...(propertyType2 === "Agricultural Land" || propertyType2 === "Plot"
            ? [
                { label: "Acres", value: "Acres" },
                { label: "Hectares", value: "Hectares" },
                { label: "Guntha", value: "Guntha" },
              ]
            : []
          ),
        ],
      },
    ]

    const user_id = JSON.parse(localStorage.getItem("authUser"))
    const user_Id = localStorage.getItem("user_Id")
    const Note = localStorage.getItem("note")
    const user_role = JSON.parse(localStorage.getItem("userRole"))

    const [rrentBasicDetails, setrrentBasicDetails] = useState({
      propertyType1: propData?.propertyDetails?.propertyType1 || "",
      lookingTo: propData?.propertyDetails?.lookingTo || "",
      postedBy: propData?.propertyDetails?.postedBy || "",
      propertyType2: propData?.propertyDetails?.propertyType2 || propertyType2,
      ageOfProperty: propData?.propertyDetails?.ageOfProperty || "",
      bhk: propData?.propertyDetails?.bhk || "",
      bathroom: {
        indians: propData?.propertyDetails?.bathroom?.indians || "",
        western: propData?.propertyDetails?.bathroom?.western || "",
      },
      balcony: propData?.propertyDetails?.balcony || "",
      terace: propData?.propertyDetails?.terace || {},
      furnishType: propData?.propertyDetails?.furnishType || "",
      furnishing: propData?.propertyDetails?.furnishing || "",
      amenities: propData?.propertyDetails?.amenities || {},
      coveredParking: propData?.propertyDetails?.coveredParking || "",
      openParking: propData?.propertyDetails?.openParking || "",
      prefferedType: propData?.propertyDetails?.prefferedType || "",
      monthlyRent: propData?.propertyDetails?.monthlyRent || "",
      maintainanceCharges: propData?.propertyDetails?.maintainanceCharges || "",
      maintainanceChargesPerMonth:
        propData?.propertyDetails?.maintainanceChargesPerMonth || "",
      availableFrom: propData?.propertyDetails?.availableFrom || "",
      securityDeposit: propData?.propertyDetails?.securityDeposit || "",
      securityDepositCustom:
        propData?.propertyDetails?.securityDepositCustom || "",
      lockInPeriod: propData?.propertyDetails?.lockInPeriod || "",
      lockInPeriodCustom: propData?.propertyDetails?.lockInPeriodCustom || "",
      brokerageCharge: propData?.propertyDetails?.brokerageCharge || "",
      brokerageChargeCustom:
        propData?.propertyDetails?.brokerageChargeCustom || "",
      brokeragenego: propData?.propertyDetails?.brokeragenego || false,
      builtUpArea: propData?.propertyDetails?.builtUpArea || "",
      carpetArea: propData?.propertyDetails?.carpetArea || "",
      legal: propData?.propertyDetails?.legal || "",
      rentals: propData?.propertyDetails?.rentals || "",
      securityDepositDiscount:
        propData?.propertyDetails?.securityDepositDiscount || "",
      homeServices: propData?.propertyDetails?.homeServices || "",
      otherOffers: propData?.propertyDetails?.otherOffers || "",
      transaType: propData?.propertyDetails?.transaType || "",
      constStatus: propData?.propertyDetails?.constStatus || "",
      plotArea: propData?.propertyDetails?.plotArea || "",
      areaUnit: propData?.propertyDetails?.areaUnit || "",
      plotLength: propData?.propertyDetails?.plotLength || "",
      plotWidth: propData?.propertyDetails?.plotWidth || "",
      widthFacingRoad: propData?.propertyDetails?.widthFacingRoad || "",
    })

    const [rrentBasicError, setrrentBasicError] = useState({
      ageOfProperty: "",
      bhk: "",
      bathroom: "",
      monthlyRent: "",
      maintainanceChargesPerMonth: "",
      availableFrom: "",
      securityDepositCustom: "",
      lockInPeriodCustom: "",
      brokerageChargeCustom: "",
      builtUpArea: "",
      transaType: "",
      constStatus: "",
      plotArea: "",
      plotLength: "",
      plotWidth: "",
      widthFacingRoad: "",
    })
    useEffect(() => {
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        propertyType1: propertytype,
        lookingTo: lookingfor,
        postedBy: postedBy,
      }))
    }, [propertytype, lookingfor])

    const handleproptype2 = value => {
      handleTabStatus(value);
      setPropertyType2(value)
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        propertyType2: value,
      }))
    }
    const [amenitiesModel, setAmentiesModel] = useState(false)
    const [showAddedFurnishing, setShowAddedFurnishing] = useState(false)

    const showModal = () => {
      setAmentiesModel(true)
    }

    const hideModel = () => {
      setAmentiesModel(false)
    }

    const handleChange = e => {
      const { name, value } = e.target

      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        [name]: value,
      }))
      setrrentBasicError(prevErrors => ({
        ...prevErrors,
        [name]: "",
      }))

      if (
        name === "carpetArea" &&
        parseInt(value) >= parseInt(rrentBasicDetails.builtUpArea)
      ) {
        setrrentBasicError(prevErrors => ({
          ...prevErrors,
          carpetArea: "Carpet area must be less than built-up area",
        }))
      } else {
        setrrentBasicError(prevErrors => ({
          ...prevErrors,
          carpetArea: "",
        }))
      }
    }

    const handle_change = (iName, value) => {
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        [iName]: value,
      }))
      setrrentBasicError(prevErrors => ({
        ...prevErrors,
        [iName]: "",
      }))
    }

    const handleAmenities = (inputName, value) => {
      setrrentBasicDetails(prevState => {
        const updatedAmenities = { ...prevState.amenities }
        if (updatedAmenities[inputName]) {
          if (updatedAmenities[inputName].includes(value)) {
            updatedAmenities[inputName] = updatedAmenities[inputName].filter(
              item => item !== value
            )
          } else {
            updatedAmenities[inputName] = [
              ...updatedAmenities[inputName],
              value,
            ]
          }
        } else {
          updatedAmenities[inputName] = [value]
        }
        return { ...prevState, amenities: updatedAmenities }
      })
    }

    const handleBHK = val => {
      console.log("handlevalue", propData?.propertyDetails?.bhk)
      setBhkValue(val)
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        bhk: val,
      }))
    }
    // const handleBathroom = val => {
    //   setBathroomValue(val)
    //   setrrentBasicDetails(prevDetails => ({
    //     ...prevDetails,
    //     bathroom: val,
    //   }))
    // }
    const handleBathroom = (type, val) => {
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        bathroom: {
          ...prevDetails.bathroom,
          [type]: val,
        },
      }))
    }

    const handleBalcony = val => {
      setBalconyValue(val)
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        balcony: val,
      }))
    }

    const [showInputField, setShowInputField] = useState(false)
    const [selectedTeraceType, setSelectedTeraceType] = useState("")
    const [lastAddedBalconyType, setLastAddedBalconyType] = useState("")

    const handleTerace = type => {
      setShowInputField(type === "Attached" || type === "Common")
      setSelectedTeraceType(type)

      const updatedTerace = {}
      updatedTerace[type] = rrentBasicDetails.terace[type] || ""
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        terace: updatedTerace,
      }))
    }

    const handleInputChange = e => {
      const value = e.target.value
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        terace: {
          ...prevDetails.terace,
          [selectedTeraceType]: value,
        },
      }))
    }

    const HandleCoveP = val => {
      setCoverPValue(val)
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        coveredParking: val,
      }))
    }
    const handleOpenP = val => {
      setOpenPValue(val)
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        openParking: val,
      }))
    }
    const handleFurnishType = val => {
      setFurnishType(val)
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        furnishType: val,
      }))
    }
    // const handletenanT = val => {
    //   setTenanTValue(val)
    //   setrrentBasicDetails(prevDetails => ({
    //     ...prevDetails,
    //     prefferedType: val,
    //   }))
    // }

    const handletenanT = val => {
      if (tenanTvalue.includes(val)) {
        setTenanTValue(prevValues => prevValues.filter(item => item !== val))
      } else {
        setTenanTValue(prevValues => [...prevValues, val])
      }

      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        prefferedType: tenanTvalue.includes(val)
          ? val
          : [...prevDetails.prefferedType, val],
      }))
    }

    const handlemaintaival = val => {
      setmaintainanceCharges(val)
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        maintainanceCharges: val,
      }))
    }
    const handlesecurityDeposit = val => {
      setsecurityDeposit(val)
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        securityDeposit: val,
      }))
    }
    const handlelockInPeriod = val => {
      setlockInPeriod(val)
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        lockInPeriod: val,
      }))
    }
    const handlebrokerageCharge = val => {
      setbrokerageCharge(val)
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        brokerageCharge: val,
      }))
    }
    const handleLegal = val => {
      setlegalvalue(val)
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        legal: val,
      }))
    }
    const handleRental = val => {
      setrentalvalue(val)
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        rentals: val,
      }))
    }
    const handleSDDiscount = val => {
      setSDDiscount(val)
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        securityDepositDiscount: val,
      }))
    }
    const handlehomeServices = val => {
      sethomeServices(val)
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        homeServices: val,
      }))
    }
    function textareachange(event) {
      settextcount(event.target.value.length)
      const stringValue = event.target.value
      const arrayValue = stringValue.split(",")
      const nonEmptyArrayValue = arrayValue.filter(item => item !== "")
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        otherOffers: nonEmptyArrayValue,
      }))
    }
    const [selectedLabel, setSelectedLabel] = useState()
    function handleSelectGroup(selectedGroup) {
      setSelectedLabel(selectedGroup.label)
      setrrentBasicDetails(prevDetails => ({
        ...prevDetails,
        areaUnit: selectedGroup.value,
      }))
    }

    // console.log(rrentBasicDetails)

    const formatAmount = amount => {
      if (amount < 1000) {
        return amount
      } else if (amount >= 1000 && amount < 100000) {
        return (amount / 1000).toFixed(2) + "k"
      } else if (amount >= 100000 && amount < 10000000) {
        return (amount / 100000).toFixed(2) + "L"
      } else if (amount >= 10000000) {
        return (amount / 10000000).toFixed(2) + "Cr"
      }
    }

    const handleSubmit = async () => {
      const newErrors = {}

      if (
        parseInt(rrentBasicDetails.carpetArea) >=
        parseInt(rrentBasicDetails.builtUpArea)
      ) {
        newErrors.carpetArea = "Carpet area must be less than built-up area"
      }

      if (rrentBasicDetails.lookingTo == "Rent") {
        if (rrentBasicDetails.ageOfProperty == "") {
          newErrors.ageOfProperty = "Age of Property is required"
        }
        if (rrentBasicDetails.bhk.trim() === "") {
          newErrors.bhk = "BHK is required"
        }
        if (rrentBasicDetails.bathroom === "") {
          newErrors.bathroom = "Bathroom is required"
        }
        if (rrentBasicDetails.monthlyRent == "") {
          newErrors.monthlyRent = "Rent of Property is required"
        }
        if (rrentBasicDetails.availableFrom == "") {
          newErrors.availableFrom = "Available date is required"
        }
        if (rrentBasicDetails.maintainanceCharges == "Seperated") {
          if (rrentBasicDetails.maintainanceChargesPerMonth === "") {
            newErrors.maintainanceChargesPerMonth =
              "Maintainance charges is required"
          }
        }
        if (rrentBasicDetails.securityDeposit == "Custom") {
          if (rrentBasicDetails.securityDepositCustom.trim() === "") {
            newErrors.securityDepositCustom = "Security deposit is required"
          }
        }
        if (rrentBasicDetails.lockInPeriod == "Custom") {
          if (rrentBasicDetails.lockInPeriodCustom.trim() === "") {
            newErrors.lockInPeriodCustom = "Lock-in period is required"
          }
        }
        if (rrentBasicDetails.brokerageCharge == "Custom") {
          if (rrentBasicDetails.brokerageChargeCustom.trim() === "") {
            newErrors.brokerageChargeCustom = "Brokerage charge is required"
          }
        }
        if (rrentBasicDetails.builtUpArea == "") {
          newErrors.builtUpArea = "Built-up area is required"
        }
      } else {
        if (rrentBasicDetails.transaType.trim() === "") {
          newErrors.transaType = "Transaction type is required"
        }
        if (
          rrentBasicDetails.propertyType2 == "Apartment" ||
          rrentBasicDetails.propertyType2 == "Independent Floor" ||
          rrentBasicDetails.propertyType2 == "Independent House" ||
          rrentBasicDetails.propertyType2 == "Villa"
        ) {
          if (rrentBasicDetails.constStatus == "Ready to Move") {
            if (rrentBasicDetails.ageOfProperty == "") {
              newErrors.ageOfProperty = "Age of Property is required"
            }
          }
          if (rrentBasicDetails.constStatus.trim() === "") {
            newErrors.constStatus = "Construction Status is required"
          }
          if (rrentBasicDetails.bhk.trim() === "") {
            newErrors.bhk = "BHK is required"
          }
          if (rrentBasicDetails.bathroom === "") {
            newErrors.bathroom = "Bathroom is required"
          }
          if (rrentBasicDetails.builtUpArea === "") {
            newErrors.builtUpArea = "Built-up area is required"
          }
          if (rrentBasicDetails.availableFrom.trim() === "") {
            newErrors.availableFrom = "Possession date is required"
          }
        }
        if (rrentBasicDetails.monthlyRent.trim() === "") {
          newErrors.monthlyRent = "Property price is required"
        }
        if (rrentBasicDetails.maintainanceChargesPerMonth === "") {
          if(rrentBasicDetails.propertyType2 == "Agricultural Land" || rrentBasicDetails.propertyType2 == "Plot"){
            delete newErrors.maintainanceChargesPerMonth;
          }else{
            newErrors.maintainanceChargesPerMonth =
              "Maintainance charges is required"
          }
        }
        // if (rrentBasicDetails.brokerageCharge.trim() == "") {
        //   newErrors.brokerageCharge = "Brokerage is required"
        // }
        if (rrentBasicDetails.brokerageCharge == "Yes") {
          if (rrentBasicDetails.brokerageChargeCustom.trim() === "") {
            newErrors.brokerageChargeCustom = "Brokerage charge is required"
          }
        }

        if (
          rrentBasicDetails.propertyType2 == "Plot" ||
          rrentBasicDetails.propertyType2 == "Agricultural Land"
        ) {
          // if (rrentBasicDetails.constStatus == "Immediate") {
          //   // if (rrentBasicDetails.ageOfProperty == "") {
          //   //   newErrors.ageOfProperty = "Age of Property is required"
          //   // }
          // }
          if (rrentBasicDetails.constStatus == "In Future") {
            if (rrentBasicDetails.availableFrom.trim() === "") {
              newErrors.availableFrom = "Possession date is required"
            }
          }
          if (rrentBasicDetails.constStatus.trim() === "") {
            newErrors.constStatus = "Possession Status is required"
          }
          if (rrentBasicDetails.plotArea == "") {
            newErrors.plotArea = "Area is required"
          }
          if (rrentBasicDetails.plotLength == "") {
            newErrors.plotLength = "Length is required"
          }
          if (rrentBasicDetails.plotWidth == "") {
            newErrors.plotWidth = "Width is required"
          }
          if (rrentBasicDetails.widthFacingRoad == "") {
            newErrors.widthFacingRoad = "Width of facing is required"
          }
        }
      }

      setrrentBasicError(newErrors)
      if (Object.values(newErrors).every(error => error === "")) {
        var status
        const token = JSON.parse(localStorage.getItem("token"))
        const url = `${HOST_URL}property/basicdetails`
        const property_id = JSON.parse(localStorage.getItem("property_id"))
        const data = {
          propertyDetails: rrentBasicDetails,
          user_id: user_Id || user_id._id,
          postedBy: user_id._id,
          note: Note,
          property_id,
        }
        var response
        await ApiPostMethodWithToken(url, data, token).then(resp => {
          if (resp.error === false) {
            localStorage.setItem(
              "property_id",
              JSON.stringify(resp.property_id)
            )
            status = true
            response = resp
          } else {
            status = false
            response = resp
          }
        })
        return { status: status, message: response.message }
      } else {
        // console.log("newErrors", newErrors)
        return { status: false, message: "Fill the required fields!.." }
      }
    }

    React.useImperativeHandle(ref, () => ({
      handleRentBasic: () => handleSubmit(),
    }))

    return (
      <React.Fragment>
        <Row>
          <Col lg={12} md={12}>
            <Row className="mb-3 d-block">
              <label
                htmlFor="txtFirstNameBilling"
                className="col-lg-12 col-form-label font-weight-bold"
              >
                Building Type
              </label>
              <div className="col-lg-12 basicDetailPropbtn">
                <div className="w-100 mb-2 btn2">
                  <Button
                    style={{
                      marginBottom: "10px",
                      marginRight: "5px",
                      borderRadius: "5px",
                    }}
                    className={`btnEqualWidth appartment ${
                      propertyType2 === "Apartment" ? "btnActive" : "btnNormal"
                    }`}
                    onClick={() => handleproptype2("Apartment")}
                  >
                    <i className="mdi mdi-city-variant-outline font-size-large"></i>{" "}
                    Apartment
                  </Button>
                  <Button
                    style={{
                      marginBottom: "10px",
                      marginRight: "5px",
                      borderRadius: "5px",
                    }}
                    className={`btnEqualWidth independentF ${
                      propertyType2 === "Independent Floor"
                        ? "btnActive"
                        : "btnNormal"
                    }`}
                    onClick={() => handleproptype2("Independent Floor")}
                  >
                    <i className="mdi mdi-home-floor-0 font-size-large"></i>{" "}
                    Independent Floor
                  </Button>
                  <Button
                    style={{
                      marginBottom: "10px",
                      marginRight: "5px",
                      borderRadius: "5px",
                    }}
                    className={`btnEqualWidth independentH ${
                      propertyType2 === "Independent House"
                        ? "btnActive"
                        : "btnNormal"
                    }`}
                    onClick={() => handleproptype2("Independent House")}
                  >
                    <i className="mdi mdi-home-outline font-size-large"></i>{" "}
                    Independent House
                  </Button>
                  <Button
                    style={{ marginBottom: "10px", borderRadius: "5px" }}
                    className={`btnEqualWidth villa ${
                      propertyType2 === "Villa" ? "btnActive" : "btnNormal"
                    }`}
                    onClick={() => handleproptype2("Villa")}
                  >
                    <i className="mdi mdi-home-city-outline font-size-large"></i>{" "}
                    Villa
                  </Button>
                </div>
                {lookingfor == "Sell" ? (
                  <React.Fragment>
                    <Button
                      style={{ marginRight: "5px", borderRadius: "5px" }}
                      className={`btnEqualWidth plot ${
                        propertyType2 === "Plot" ? "btnActive" : "btnNormal"
                      }`}
                      onClick={() => handleproptype2("Plot")}
                    >
                      <i className="mdi mdi-home-city-outline font-size-large"></i>{" "}
                      Plot
                    </Button>
                    <Button
                      style={{ borderRadius: "5px" }}
                      className={`btnEqualWidth agriculturalLand ${
                        propertyType2 === "Agricultural Land"
                          ? "btnActive"
                          : "btnNormal"
                      }`}
                      onClick={() => handleproptype2("Agricultural Land")}
                    >
                      <i className="mdi mdi-home-city-outline font-size-large"></i>{" "}
                      Agricultural Land
                    </Button>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
            </Row>
          </Col>
        </Row>
        {propertyType2 == "Plot" || propertyType2 == "Agricultural Land" ? (
          <React.Fragment>
            <Row>
              <Col lg={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="transaType"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Transaction Type
                  </label>
                  <ButtonGroup>
                    <Button
                      style={{ marginRight: "5px", borderRadius: "5px" }}
                      className={
                        rrentBasicDetails.transaType === "New Booking"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() => handle_change("transaType", "New Booking")}
                    >
                      New Booking
                    </Button>
                    <Button
                      style={{ borderRadius: "5px" }}
                      className={
                        rrentBasicDetails.transaType === "Resale"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() => handle_change("transaType", "Resale")}
                    >
                      Resale
                    </Button>
                  </ButtonGroup>
                  {rrentBasicError.transaType && (
                    <span className="error">{rrentBasicError.transaType}</span>
                  )}
                </Row>
              </Col>
              <Col lg={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="constStatus"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Possession Status
                  </label>
                  <ButtonGroup>
                    <Button
                      style={{ marginRight: "5px", borderRadius: "5px" }}
                      className={
                        rrentBasicDetails.constStatus === "Immediate"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() => handle_change("constStatus", "Immediate")}
                    >
                      Immediate
                    </Button>
                    <Button
                      style={{ borderRadius: "5px" }}
                      className={
                        rrentBasicDetails.constStatus === "In Future"
                          ? "btnActive"
                          : "btnNormal"
                      }
                      onClick={() => handle_change("constStatus", "In Future")}
                    >
                      In Future
                    </Button>
                  </ButtonGroup>
                  {rrentBasicError.constStatus && (
                    <span className="error">{rrentBasicError.constStatus}</span>
                  )}
                </Row>
              </Col>
              {rrentBasicDetails.constStatus === "Immediate" ? (
                // <Col md={6}>
                //   <Row className="mb-3 d-block">
                //     <label
                //       htmlFor="ageOfProperty"
                //       className="col-lg-12 col-form-label requiredfield font-weight-bold"
                //     >
                //       Age of property (in year)
                //     </label>
                //     <div className="col-lg-12">
                //       <Input
                //         id="ageOfProperty"
                //         name="ageOfProperty"
                //         type="number"
                //         className={`form-control`}
                //         placeholder="Enter property age"
                //         value={rrentBasicDetails.ageOfProperty}
                //         onChange={handleChange}
                //         min={0}
                //         onWheel={e => e.target.blur()}
                //       />
                //       {rrentBasicError.ageOfProperty && (
                //         <span className="error">
                //           {rrentBasicError.ageOfProperty}
                //         </span>
                //       )}
                //     </div>
                //   </Row>
                // </Col>
                <></>
              ) : rrentBasicDetails.constStatus === "In Future" ? (
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="txtFirstNameBilling"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Possession Date
                    </label>
                    <div className="col-lg-12">
                      <Input
                        className="form-control"
                        name="availableFrom"
                        type="date"
                        id="availableFrom"
                        value={rrentBasicDetails.availableFrom}
                        onChange={handleChange}
                      />
                      {rrentBasicError.availableFrom && (
                        <span className="error">
                          {rrentBasicError.availableFrom}
                        </span>
                      )}
                    </div>
                  </Row>
                </Col>
              ) : (
                ""
              )}
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    {propertyType2 == "Agricultural Land" ? "Price" : "Plot Price"}
                  </label>
                  <div className="col-lg-12 d-flex built-up">
                    <Input
                      id="monthlyRent"
                      name="monthlyRent"
                      type="number"
                      className={`form-control`}
                      // placeholder= {lookingfor == 'Rent' ? "Enter monthly rent" : "Enter cost"}
                      value={rrentBasicDetails.monthlyRent}
                      onChange={handleChange}
                      min={0}
                      onWheel={e => e.target.blur()}
                    />
                    <span className="input-group-append">
                      <span className="input-group-text font-weight-bold">
                        ₹{formatAmount(rrentBasicDetails.monthlyRent)}
                      </span>
                    </span>
                  </div>
                  {rrentBasicError.monthlyRent && (
                    <span className="error">{rrentBasicError.monthlyRent}</span>
                  )}
                </Row>
              </Col>
            </Row>
            <Row>
              {/* {propertyType2 != "Agricultural Land" ? (
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="txtFirstNameBilling"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Maintenance Charges (per month)
                    </label>
                    <div className="col-lg-12 d-flex built-up">
                      <Input
                        className="form-control"
                        name="maintainanceChargesPerMonth"
                        type="number"
                        id="maintainanceChargesPerMonth"
                        value={rrentBasicDetails.maintainanceChargesPerMonth}
                        onChange={handleChange}
                        min={0}
                        onWheel={e => e.target.blur()}
                      />
                      <span className="input-group-append">
                        <span className="input-group-text font-weight-bold">
                          ₹
                          {formatAmount(
                            rrentBasicDetails.maintainanceChargesPerMonth
                          )}
                        </span>
                      </span>
                    </div>
                    {rrentBasicError.maintainanceChargesPerMonth && (
                      <span className="error">
                        {rrentBasicError.maintainanceChargesPerMonth}
                      </span>
                    )}
                  </Row>
                </Col>
              ) : ''} */}
              <Col lg={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Do you charge brokerage?
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100">
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          brokerageCharge === "Yes" ? "btnActive" : "btnNormal"
                        }
                        onClick={() => handlebrokerageCharge("Yes")}
                      >
                        Yes
                      </Button>
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          brokerageCharge === "No" ? "btnActive" : "btnNormal"
                        }
                        onClick={() => handlebrokerageCharge("No")}
                      >
                        No
                      </Button>
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
              {brokerageCharge == "Yes" ? (
                <React.Fragment>
                  <Col md={6}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="txtFirstNameBilling"
                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                      >
                        Brokerage ( In Rupees)
                      </label>
                      <div className="col-lg-12 d-flex built-up">
                        <Input
                          className="form-control"
                          name="brokerageChargeCustom"
                          type="number"
                          id="brokerageChargeCustom"
                          value={rrentBasicDetails.brokerageChargeCustom}
                          onChange={handleChange}
                          min={0}
                          onWheel={e => e.target.blur()}
                        />
                        <span className="input-group-append">
                          <span className="input-group-text font-weight-bold">
                            ₹
                            {formatAmount(
                              rrentBasicDetails.brokerageChargeCustom
                            )}
                          </span>
                        </span>
                      </div>
                      {rrentBasicError.brokerageChargeCustom && (
                        <span className="error">
                          {rrentBasicError.brokerageChargeCustom}
                        </span>
                      )}
                    </Row>
                  </Col>
                  <Col lg={6} className="d-flex align-items-center">
                    <Button
                      style={{ marginRight: "5px" }}
                      className={"btnNormal mt-3"}
                      onClick={() =>
                        handle_change(
                          "brokeragenego",
                          !rrentBasicDetails.brokeragenego
                        )
                      }
                    >
                      <Input
                        type="checkbox"
                        className="me-1"
                        name="brokeragenego"
                        onChange={() =>
                          handle_change(
                            "brokeragenego",
                            !rrentBasicDetails.brokeragenego
                          )
                        }
                        checked={rrentBasicDetails.brokeragenego === true}
                      />
                      Brokerage Negotiable
                    </Button>
                  </Col>
                </React.Fragment>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    {propertyType2 == "Agricultural Land" ? "Land Area" : "Plot Area"}
                  </label>
                  <div className="col-lg-12 d-flex">
                    <Input
                      id="plotArea"
                      name="plotArea"
                      type="number"
                      className={`form-control`}
                      value={rrentBasicDetails.plotArea}
                      onChange={handleChange}
                      min={0}
                      onWheel={e => e.target.blur()}
                    />
                    {/* {propertyType2 != "Agricultural Land" || propertyType2 != "Plot" && (
                      <span className="input-group-append">
                        <span className="input-group-text">Sq.ft</span>
                      </span>
                    )} */}
                  </div>
                  {rrentBasicError.plotArea && (
                    <span className="error">{rrentBasicError.plotArea}</span>
                  )}
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Area Unit
                  </label>
                  <div className="col-lg-12">
                    <Select
                      id="areaUnit"
                      name="areaUnit"
                      value={{
                        label: selectedLabel,
                        value: rrentBasicDetails.areaUnit,
                      }}
                      onChange={selectedValue => {
                        // console.log("selected", selectedValue);
                        handleSelectGroup(selectedValue)
                      }}
                      options={optionGroup}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Length
                  </label>
                  <div className="col-lg-12 d-flex built-up">
                    <Input
                      id="plotLength"
                      name="plotLength"
                      type="number"
                      className={`form-control`}
                      // placeholder="Enter Built Up Area"
                      value={rrentBasicDetails.plotLength}
                      onChange={handleChange}
                      min={0}
                      onWheel={e => e.target.blur()}
                    />
                    <span className="input-group-append">
                      <span className="input-group-text">
                        {rrentBasicDetails?.areaUnit || "Sq.ft"}
                      </span>
                    </span>
                  </div>
                  {rrentBasicError.plotLength && (
                    <span className="error">{rrentBasicError.plotLength}</span>
                  )}
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Width
                  </label>
                  <div className="col-lg-12 d-flex built-up">
                    <Input
                      id="plotWidth"
                      name="plotWidth"
                      type="number"
                      className={`form-control`}
                      // placeholder="Enter Built Up Area"
                      value={rrentBasicDetails.plotWidth}
                      onChange={handleChange}
                      min={0}
                      onWheel={e => e.target.blur()}
                    />
                    <span className="input-group-append">
                      <span className="input-group-text">
                        {rrentBasicDetails?.areaUnit || "Sq.ft"}
                      </span>
                    </span>
                  </div>
                  {rrentBasicError.plotWidth && (
                    <span className="error">{rrentBasicError.plotWidth}</span>
                  )}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="widthFacingRoad"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Width of Facing Road
                  </label>
                  <div className="col-lg-12 d-flex built-up">
                    <Input
                      id="widthFacingRoad"
                      name="widthFacingRoad"
                      type="number"
                      className={`form-control`}
                      // placeholder= {lookingfor == 'Rent' ? "Enter monthly rent" : "Enter cost"}
                      value={rrentBasicDetails.widthFacingRoad}
                      onChange={handleChange}
                      min={0}
                      onWheel={e => e.target.blur()}
                    />
                    <span className="input-group-append">
                      <span className="input-group-text">mt</span>
                    </span>
                  </div>
                  {rrentBasicError.widthFacingRoad && (
                    <span className="error">
                      {rrentBasicError.widthFacingRoad}
                    </span>
                  )}
                </Row>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {lookingfor == "Sell" ? (
              <Row>
                <Col lg={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="transaType"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Transaction Type
                    </label>
                    <ButtonGroup>
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          rrentBasicDetails.transaType === "New Booking"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handle_change("transaType", "New Booking")
                        }
                      >
                        New Booking
                      </Button>
                      <Button
                        style={{ borderRadius: "5px" }}
                        className={
                          rrentBasicDetails.transaType === "Resale"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() => handle_change("transaType", "Resale")}
                      >
                        Resale
                      </Button>
                    </ButtonGroup>
                    {rrentBasicError.transaType && (
                      <span className="error">
                        {rrentBasicError.transaType}
                      </span>
                    )}
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="constStatus"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Construction Status
                    </label>
                    <ButtonGroup>
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          rrentBasicDetails.constStatus === "Ready to Move"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handle_change("constStatus", "Ready to Move")
                        }
                      >
                        Ready to Move
                      </Button>
                      <Button
                        style={{ borderRadius: "5px" }}
                        className={
                          rrentBasicDetails.constStatus === "Under Construction"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handle_change("constStatus", "Under Construction")
                        }
                      >
                        Under Construction
                      </Button>
                    </ButtonGroup>
                    {rrentBasicError.constStatus && (
                      <span className="error">
                        {rrentBasicError.constStatus}
                      </span>
                    )}
                  </Row>
                </Col>
                {rrentBasicDetails.constStatus === "Ready to Move" ? (
                  <Col md={12}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="ageOfProperty"
                        className="col-lg-12 col-form-label requiredfield font-weight-bold"
                      >
                        Age of property (in year)
                      </label>
                      <div className="col-lg-12">
                        <Input
                          id="ageOfProperty"
                          name="ageOfProperty"
                          type="number"
                          className={`form-control`}
                          placeholder="Enter property age"
                          value={rrentBasicDetails.ageOfProperty}
                          onChange={handleChange}
                          min={0}
                          onWheel={e => e.target.blur()}
                        />
                        {rrentBasicError.ageOfProperty && (
                          <span className="error">
                            {rrentBasicError.ageOfProperty}
                          </span>
                        )}
                      </div>
                    </Row>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            ) : (
              <Row>
                <Col md={12}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="ageOfProperty"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Age of property (in year)
                    </label>
                    <div className="col-lg-12">
                      <Input
                        id="ageOfProperty"
                        name="ageOfProperty"
                        type="number"
                        className={`form-control`}
                        placeholder="Enter property age"
                        value={rrentBasicDetails.ageOfProperty}
                        onChange={handleChange}
                        min={0}
                        onWheel={e => e.target.blur()}
                      />
                      {rrentBasicError.ageOfProperty && (
                        <span className="error">
                          {rrentBasicError.ageOfProperty}
                        </span>
                      )}
                    </div>
                  </Row>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    BHK
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 buttonContainerbhk">
                      {bhkarray.map(bhk => (
                        <Button
                          key={bhk.id}
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          className={
                            bhkvalue === bhk.value ? "btnActive" : "btnNormal"
                          }
                          onClick={() => handleBHK(bhk.value)}
                        >
                          {bhk.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                    {rrentBasicError.bhk && (
                      <span className="error">{rrentBasicError.bhk}</span>
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Washroom
                  </label>
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label  font-weight-bold"
                  >
                    Indian
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 buttonContainer">
                      {bathroomarray.map((bathroom, index) => (
                        <Button
                          key={index}
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          className={
                            bathroom.value == rrentBasicDetails.bathroom.indians
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handleBathroom("indians", bathroom.value)
                          }
                        >
                          {bathroom.value}
                        </Button>
                      ))}
                    </ButtonGroup>

                    {/* Display error message for Indian bathroom */}
                    {rrentBasicError.bathroom && (
                      <span className="error">{rrentBasicError.bathroom}</span>
                    )}
                  </div>
                </Row>
              </Col>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label  font-weight-bold"
                  >
                    Western
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 buttonContainer">
                      {bathroomarray.map((bathroom, index) => (
                        <Button
                          key={index}
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          className={
                            bathroom.value == rrentBasicDetails.bathroom.western
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handleBathroom("western", bathroom.value)
                          }
                        >
                          {bathroom.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                    {/* Display error message for Western bathroom */}
                    {rrentBasicError.bathroom && (
                      <span className="error">{rrentBasicError.bathroom}</span>
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Balcony
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 buttonContainer">
                      {balcoveropenpararray.map((balcony, index) => (
                        <Button
                          key={index}
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          className={
                            balconyvalue === balcony.value
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() => handleBalcony(balcony.value)}
                        >
                          {balcony.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Terrace
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100">
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          selectedTeraceType === "Attached"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() => handleTerace("Attached")}
                      >
                        Attached
                      </Button>
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          selectedTeraceType === "Common"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() => handleTerace("Common")}
                      >
                        Common
                      </Button>
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          selectedTeraceType === "N/A"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() => handleTerace("N/A")}
                      >
                        N/A
                      </Button>
                    </ButtonGroup>
                    {/* Render input field if Attached or Common is selected */}
                    {showInputField && (
                      <div className="col-lg-6 d-flex mt-2 built-up">
                        <Input
                          type="text"
                          value={
                            rrentBasicDetails.terace[selectedTeraceType] || ""
                          }
                          onChange={handleInputChange}
                          placeholder={`Enter ${selectedTeraceType} terace value...`}
                          className="input-balcony form-control"
                        />
                        <span className="input-group-append">
                          <span className="input-group-text">Sq.ft</span>
                        </span>
                      </div>
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Furnish Type
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 buttonContainerTenant">
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          furnishtype === "Fully Furnished"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() => handleFurnishType("Fully Furnished")}
                      >
                        Fully Furnished
                      </Button>
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          furnishtype === "Semi Furnished"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() => handleFurnishType("Semi Furnished")}
                      >
                        Semi Furnished
                      </Button>
                      <Button
                        style={{ marginRight: "5px", borderRadius: "5px" }}
                        className={
                          furnishtype === "Unfurnished"
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() => handleFurnishType("Unfurnished")}
                      >
                        Unfurnished
                      </Button>
                    </ButtonGroup>
                    {/* {furnishtype == "Fully Furnished" ||
                    furnishtype == "Semi Furnished" ? (
                      <React.Fragment>
                        <a href="javascript:void(0)" onClick={showModal}>
                          + Add Furnishings / Amenities
                        </a>
                        <Modal
                          className="amenitiesModal"
                          show={amenitiesModel}
                          onHide={hideModel}
                          size="lg"
                          aria-labelledby="example-custom-modal-styling-title"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>
                              Add Furnishings and Amenities
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body className="amenititiesModal">
                            <Row>
                              <Col md={12}>
                                <Row className="mb-3 d-block ">
                                  <label
                                    htmlFor="txtFirstNameBilling"
                                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                                  >
                                    Flat Furnishing
                                  </label>
                                  <div className="col-lg-12">
                                    <ButtonGroup className="w-100 amenitiesgrid">
                                      {flatfurnish.map(face => (
                                        <Button
                                          key={face.id}
                                          style={{ borderRadius: "5px" }}
                                          className={
                                            rrentBasicDetails.amenities &&
                                            rrentBasicDetails.amenities
                                              .inFlatFurnishing &&
                                            rrentBasicDetails.amenities.inFlatFurnishing.includes(
                                              face.value
                                            )
                                              ? "btnActive"
                                              : "btnNormal"
                                          }
                                          onClick={() =>
                                            handleAmenities(
                                              "inFlatFurnishing",
                                              face.value
                                            )
                                          }
                                        >
                                          <Input
                                            type="checkbox"
                                            name="inFlatFurnishing"
                                            value={face.value}
                                            onChange={() =>
                                              handleAmenities(
                                                "inFlatFurnishing",
                                                face.value
                                              )
                                            }
                                            style={{ opacity: 0 }}
                                          />
                                          {face.value}
                                        </Button>
                                      ))}
                                    </ButtonGroup>
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <Row className="mb-3 d-block">
                                  <label
                                    htmlFor="txtFirstNameBilling"
                                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                                  >
                                    Society Amenities
                                  </label>
                                  <div className="col-lg-12">
                                    <ButtonGroup className="w-100 amenitiesgrid">
                                      {Societyamen.map(face => (
                                        <Button
                                          key={face.id}
                                          style={{ borderRadius: "5px" }}
                                          className={
                                            rrentBasicDetails.amenities &&
                                            rrentBasicDetails.amenities
                                              .inSocietyAmenities &&
                                            rrentBasicDetails.amenities.inSocietyAmenities.includes(
                                              face.value
                                            )
                                              ? "btnActive"
                                              : "btnNormal"
                                          }
                                          onClick={() =>
                                            handleAmenities(
                                              "inSocietyAmenities",
                                              face.value
                                            )
                                          }
                                        >
                                          <Input
                                            type="checkbox"
                                            name="inSocietyAmenities"
                                            value={face.value}
                                            onChange={() =>
                                              handleAmenities(
                                                "inSocietyAmenities",
                                                face.value
                                              )
                                            }
                                            style={{ opacity: 0 }}
                                          />
                                          {face.value}
                                        </Button>
                                      ))}
                                    </ButtonGroup>
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                            <Button
                              className="commongradient w-100"
                              style={{ color: "#fff" }}
                              onClick={() => {
                                setShowAddedFurnishing(true)
                                hideModel()
                              }}
                            >
                              Save
                            </Button>
                          </Modal.Body>
                        </Modal>
                        {showAddedFurnishing && (
                          <React.Fragment>
                            <ul>
                              {Object.entries(rrentBasicDetails.amenities).map(
                                ([key, values]) => (
                                  <li key={key}>
                                    {values.map((amenities, i) => (
                                      <>
                                        <span
                                          key={i}
                                          className="amenitiesvalue"
                                        >
                                          {amenities}
                                        </span>
                                      </>
                                    ))}
                                  </li>
                                )
                              )}
                            </ul>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ) : (
                      ""
                    )} */}
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Covered Parking
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 buttonContainer2">
                      {balcoveropenpararray.map((covP, i) => (
                        <Button
                          key={i}
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          className={
                            coverPvalue === covP.value
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() => HandleCoveP(covP.value)}
                        >
                          {covP.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Open Parking
                  </label>
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 buttonContainer2">
                      {balcoveropenpararray.map((opeP, i) => (
                        <Button
                          key={i}
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          className={
                            openPvalue === opeP.value
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() => handleOpenP(opeP.value)}
                        >
                          {opeP.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                </Row>
              </Col>
            </Row>
            {lookingfor == "Rent" ? (
              <Row>
                <Col md={12}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="txtFirstNameBilling"
                      className="col-lg-12 col-form-label font-weight-bold"
                    >
                      Preferred Tenant Type
                    </label>
                    <div className="col-lg-12">
                      <div className="w-100 buttonContainerTenant">
                        {tenanttypearray.map((tenanT, i) => (
                          <Button
                            key={i}
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              tenanTvalue.includes(tenanT.value)
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() => handletenanT(tenanT.value)}
                          >
                            {tenanT.value}
                          </Button>
                        ))}
                      </div>
                    </div>
                  </Row>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row className={lookingfor == "Rent" ? "" : "flex-row-reverse"}>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    {lookingfor == "Rent" ? "Monthly Rent" : "Cost"}
                  </label>
                  <div className="col-lg-12 d-flex built-up">
                    <Input
                      id="monthlyRent"
                      name="monthlyRent"
                      type="number"
                      className={`form-control`}
                      placeholder={
                        lookingfor == "Rent"
                          ? "Enter monthly rent"
                          : "Enter cost"
                      }
                      value={rrentBasicDetails.monthlyRent}
                      onChange={handleChange}
                      min={0}
                      onWheel={e => e.target.blur()}
                    />
                    <span className="input-group-append">
                      <span className="input-group-text font-weight-bold">
                        ₹{formatAmount(rrentBasicDetails.monthlyRent)}
                      </span>
                    </span>
                  </div>
                  {rrentBasicError.monthlyRent && (
                    <span className="error">{rrentBasicError.monthlyRent}</span>
                  )}
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    {lookingfor == "Rent"
                      ? "Available From"
                      : "Possession Date"}
                  </label>
                  <div className="col-lg-12">
                    <Input
                      className="form-control"
                      name="availableFrom"
                      type="date"
                      id="availableFrom"
                      value={rrentBasicDetails.availableFrom}
                      onChange={handleChange}
                    />
                    {rrentBasicError.availableFrom && (
                      <span className="error">
                        {rrentBasicError.availableFrom}
                      </span>
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
            {lookingfor == "Rent" ? (
              <>
                <Row>
                  <Col md={6}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="txtFirstNameBilling"
                        className="col-lg-12 col-form-label font-weight-bold"
                      >
                        Maintenance Charges
                      </label>
                      <div className="col-lg-12">
                        <ButtonGroup className="w-100">
                          <Button
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              maintainanceCharges === "Include In Rent"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() => handlemaintaival("Include In Rent")}
                          >
                            Include In Rent
                          </Button>
                          <Button
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              maintainanceCharges === "Seperated"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() => handlemaintaival("Seperated")}
                          >
                            Seperated
                          </Button>
                        </ButtonGroup>
                      </div>
                    </Row>
                  </Col>
                  {maintainanceCharges == "Seperated" && (
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="txtFirstNameBilling"
                          className="col-lg-12 col-form-label requiredfield font-weight-bold"
                        >
                          Maintenance Charges (per month)
                        </label>
                        <div className="col-lg-12 d-flex built-up">
                          <Input
                            className="form-control"
                            name="maintainanceChargesPerMonth"
                            type="number"
                            id="maintainanceChargesPerMonth"
                            value={
                              rrentBasicDetails.maintainanceChargesPerMonth
                            }
                            onChange={handleChange}
                            min={0}
                            onWheel={e => e.target.blur()}
                          />
                          <span className="input-group-append">
                            <span className="input-group-text font-weight-bold">
                              ₹
                              {formatAmount(
                                rrentBasicDetails.maintainanceChargesPerMonth
                              )}
                            </span>
                          </span>
                        </div>
                        {rrentBasicError.maintainanceChargesPerMonth && (
                          <span className="error">
                            {rrentBasicError.maintainanceChargesPerMonth}
                          </span>
                        )}
                      </Row>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md={12}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="txtFirstNameBilling"
                        className="col-lg-12 col-form-label font-weight-bold"
                      >
                        Security Deposit
                      </label>
                      <div className="col-lg-12">
                        <ButtonGroup className="w-100 buttonContainerTenant">
                          <Button
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              securityDeposit === "None"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() => handlesecurityDeposit("None")}
                          >
                            None
                          </Button>
                          <Button
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              securityDeposit === "1 Month"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() => handlesecurityDeposit("1 Month")}
                          >
                            1 Month
                          </Button>
                          <Button
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              securityDeposit === "2 Month"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() => handlesecurityDeposit("2 Month")}
                          >
                            2 Month
                          </Button>
                          <Button
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              securityDeposit === "Custom"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() => handlesecurityDeposit("Custom")}
                          >
                            Custom
                          </Button>
                        </ButtonGroup>
                      </div>
                    </Row>
                  </Col>
                  {securityDeposit == "Custom" && (
                    <Col md={12}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="txtFirstNameBilling"
                          className="col-lg-12 col-form-label requiredfield font-weight-bold"
                        >
                          Security Deposit
                        </label>
                        <div className="col-lg-12 d-flex built-up">
                          <Input
                            className="form-control"
                            name="securityDepositCustom"
                            type="number"
                            id="securityDepositCustom"
                            value={rrentBasicDetails.securityDepositCustom}
                            onChange={handleChange}
                            min={0}
                            onWheel={e => e.target.blur()}
                          />
                          <span className="input-group-append">
                            <span className="input-group-text font-weight-bold">
                              ₹
                              {formatAmount(
                                rrentBasicDetails.securityDepositCustom
                              )}
                            </span>
                          </span>
                        </div>
                        {rrentBasicError.securityDepositCustom && (
                          <span className="error">
                            {rrentBasicError.securityDepositCustom}
                          </span>
                        )}
                      </Row>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md={12}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="txtFirstNameBilling"
                        className="col-lg-12 col-form-label font-weight-bold"
                      >
                        Lock-in Period
                      </label>
                      <div className="col-lg-12">
                        <ButtonGroup className="w-100 buttonContainerTenant">
                          <Button
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              lockInPeriod === "None"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() => handlelockInPeriod("None")}
                          >
                            None
                          </Button>
                          <Button
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              lockInPeriod === "1 Month"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() => handlelockInPeriod("1 Month")}
                          >
                            1 Month
                          </Button>
                          <Button
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              lockInPeriod === "6 Month"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() => handlelockInPeriod("6 Month")}
                          >
                            6 Month
                          </Button>
                          <Button
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              lockInPeriod === "Custom"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() => handlelockInPeriod("Custom")}
                          >
                            Custom
                          </Button>
                        </ButtonGroup>
                      </div>
                    </Row>
                  </Col>
                  {lockInPeriod == "Custom" && (
                    <Col md={12}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="txtFirstNameBilling"
                          className="col-lg-12 col-form-label requiredfield font-weight-bold"
                        >
                          Lock-in Period
                        </label>
                        <div className="col-lg-12">
                          <Input
                            className="form-control"
                            name="lockInPeriodCustom"
                            type="number"
                            id="lockInPeriodCustom"
                            value={rrentBasicDetails.lockInPeriodCustom}
                            onChange={handleChange}
                            min={0}
                            onWheel={e => e.target.blur()}
                          />
                          {rrentBasicError.lockInPeriodCustom && (
                            <span className="error">
                              {rrentBasicError.lockInPeriodCustom}
                            </span>
                          )}
                        </div>
                      </Row>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md={12}>
                    <Row className="mb-3 d-block">
                      <label
                        htmlFor="txtFirstNameBilling"
                        className="col-lg-12 col-form-label font-weight-bold"
                      >
                        Do you charge brokerage?
                      </label>
                      <div className="col-lg-12">
                        <ButtonGroup className="w-100 buttonContainerTenant">
                          <Button
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              brokerageCharge === "None"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() => handlebrokerageCharge("None")}
                          >
                            None
                          </Button>
                          <Button
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              brokerageCharge === "15 Days"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() => handlebrokerageCharge("15 Days")}
                          >
                            15 Days
                          </Button>
                          <Button
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              brokerageCharge === "30 Days"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() => handlebrokerageCharge("30 Days")}
                          >
                            30 Days
                          </Button>
                          <Button
                            style={{ marginRight: "5px", borderRadius: "5px" }}
                            className={
                              brokerageCharge === "Custom"
                                ? "btnActive"
                                : "btnNormal"
                            }
                            onClick={() => handlebrokerageCharge("Custom")}
                          >
                            Custom
                          </Button>
                        </ButtonGroup>
                      </div>
                    </Row>
                  </Col>
                  {brokerageCharge == "Custom" && (
                    <Col md={12}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="txtFirstNameBilling"
                          className="col-lg-12 col-form-label requiredfield font-weight-bold"
                        >
                          Brokerage ( In Rupees)
                        </label>
                        <div className="col-lg-12 d-flex built-up">
                          <Input
                            className="form-control"
                            name="brokerageChargeCustom"
                            type="number"
                            id="brokerageChargeCustom"
                            value={rrentBasicDetails.brokerageChargeCustom}
                            onChange={handleChange}
                            min={0}
                            onWheel={e => e.target.blur()}
                          />
                          <span className="input-group-append">
                            <span className="input-group-text font-weight-bold">
                              ₹
                              {formatAmount(
                                rrentBasicDetails.brokerageChargeCustom
                              )}
                            </span>
                          </span>
                        </div>
                        {rrentBasicError.brokerageChargeCustom && (
                          <span className="error">
                            {rrentBasicError.brokerageChargeCustom}
                          </span>
                        )}
                      </Row>
                    </Col>
                  )}
                </Row>
              </>
            ) : (
              <Row>
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="txtFirstNameBilling"
                      className="col-lg-12 col-form-label requiredfield font-weight-bold"
                    >
                      Maintenance Charges (per month)
                    </label>
                    <div className="col-lg-12 d-flex built-up">
                      <Input
                        className="form-control"
                        name="maintainanceChargesPerMonth"
                        type="number"
                        id="maintainanceChargesPerMonth"
                        value={rrentBasicDetails.maintainanceChargesPerMonth}
                        onChange={handleChange}
                        min={0}
                        onWheel={e => e.target.blur()}
                      />
                      <span className="input-group-append">
                        <span className="input-group-text font-weight-bold">
                          ₹
                          {formatAmount(
                            rrentBasicDetails.maintainanceChargesPerMonth
                          )}
                        </span>
                      </span>
                    </div>
                    {rrentBasicError.maintainanceChargesPerMonth && (
                      <span className="error">
                        {rrentBasicError.maintainanceChargesPerMonth}
                      </span>
                    )}
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="txtFirstNameBilling"
                      className="col-lg-12 col-form-label font-weight-bold"
                    >
                      Do you charge brokerage?
                    </label>
                    <div className="col-lg-12">
                      <ButtonGroup className="w-100">
                        <Button
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          className={
                            brokerageCharge === "Yes"
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() => handlebrokerageCharge("Yes")}
                        >
                          Yes
                        </Button>
                        <Button
                          style={{ marginRight: "5px", borderRadius: "5px" }}
                          className={
                            brokerageCharge === "No" ? "btnActive" : "btnNormal"
                          }
                          onClick={() => handlebrokerageCharge("No")}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Row>
                </Col>
                {brokerageCharge == "Yes" ? (
                  <React.Fragment>
                    <Col md={6}>
                      <Row className="mb-3 d-block">
                        <label
                          htmlFor="txtFirstNameBilling"
                          className="col-lg-12 col-form-label requiredfield font-weight-bold"
                        >
                          Brokerage ( In Rupees)
                        </label>
                        <div className="col-lg-12 d-flex built-up">
                          <Input
                            className="form-control"
                            name="brokerageChargeCustom"
                            type="number"
                            id="brokerageChargeCustom"
                            value={rrentBasicDetails.brokerageChargeCustom}
                            onChange={handleChange}
                            min={0}
                            onWheel={e => e.target.blur()}
                          />
                          <span className="input-group-append">
                            <span className="input-group-text font-weight-bold">
                              ₹
                              {formatAmount(
                                rrentBasicDetails.brokerageChargeCustom
                              )}
                            </span>
                          </span>
                        </div>
                        {rrentBasicError.brokerageChargeCustom && (
                          <span className="error">
                            {rrentBasicError.brokerageChargeCustom}
                          </span>
                        )}
                      </Row>
                    </Col>
                    <Col lg={6} className="d-flex align-items-center">
                      <Button
                        style={{ marginRight: "5px" }}
                        className={"btnNormal mt-3"}
                        onClick={() =>
                          handle_change(
                            "brokeragenego",
                            !rrentBasicDetails.brokeragenego
                          )
                        }
                      >
                        <Input
                          type="checkbox"
                          className="me-1"
                          name="brokeragenego"
                          onChange={() =>
                            handle_change(
                              "brokeragenego",
                              !rrentBasicDetails.brokeragenego
                            )
                          }
                          checked={rrentBasicDetails.brokeragenego === true}
                        />
                        Brokerage Negotiable
                      </Button>
                    </Col>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </Row>
            )}
            <Row>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label requiredfield font-weight-bold"
                  >
                    Built Up Area
                  </label>
                  <div className="col-lg-12 d-flex built-up">
                    <Input
                      id="builtUpArea"
                      name="builtUpArea"
                      type="number"
                      className={`form-control`}
                      // placeholder="Enter Built Up Area"
                      value={rrentBasicDetails.builtUpArea}
                      onChange={handleChange}
                      min={0}
                      onWheel={e => e.target.blur()}
                    />
                    <span className="input-group-append">
                      <span className="input-group-text">Sq.ft</span>
                    </span>
                  </div>
                  {rrentBasicError.builtUpArea && (
                    <span className="error">{rrentBasicError.builtUpArea}</span>
                  )}
                </Row>
              </Col>
              <Col md={6}>
                <Row className="mb-3 d-block">
                  <label
                    htmlFor="txtFirstNameBilling"
                    className="col-lg-12 col-form-label font-weight-bold"
                  >
                    Carpet Area (Optional)
                  </label>
                  <div className="col-lg-12 d-flex built-up">
                    <Input
                      className="form-control"
                      name="carpetArea"
                      type="number"
                      // placeholder="Enter Carpet Area"
                      id="carpetArea"
                      value={rrentBasicDetails.carpetArea}
                      onChange={handleChange}
                      min={0}
                      onWheel={e => e.target.blur()}
                    />
                    <span className="input-group-append">
                      <span className="input-group-text">Sq.ft</span>
                    </span>
                  </div>
                  {rrentBasicError.carpetArea && (
                    <span className="error">{rrentBasicError.carpetArea}</span>
                  )}
                </Row>
              </Col>
              {(propertyType2 == "Independent House" ||
                propertyType2 == "Villa") &&
              lookingfor == "Sell" ? (
                <Col md={6}>
                  <Row className="mb-3 d-block">
                    <label
                      htmlFor="txtFirstNameBilling"
                      className="col-lg-12 col-form-label font-weight-bold"
                    >
                      Plot Area (Optional)
                    </label>
                    <div className="col-lg-12 d-flex built-up">
                      <Input
                        className="form-control"
                        name="plotArea"
                        type="number"
                        // placeholder="Enter Carpet Area"
                        id="plotArea"
                        value={rrentBasicDetails.plotArea}
                        onChange={handleChange}
                        min={0}
                        onWheel={e => e.target.blur()}
                      />
                      <span className="input-group-append">
                        <span className="input-group-text">Sq.ft</span>
                      </span>
                    </div>
                  </Row>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </React.Fragment>
        )}
        {/* <h6 className="mt-2">Offers-Increase upto 50% leads (Optional)</h6>
        <Row>
          <Col md={12}>
            <Row className="mb-3">
              <label
                htmlFor="txtFirstNameBilling"
                className="col-lg-2 col-form-label font-weight-bold"
              >
                Legal
              </label>
              <div className="col-lg-10">
                <ButtonGroup className="w-100">
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      legalvalue === "Free Rental Agreement"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() => handleLegal("Free Rental Agreement")}
                  >
                    Free Rental Agreement
                  </Button>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      legalvalue === "Free Police Verification"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() => handleLegal("Free Police Verification")}
                  >
                    Free Police Verification
                  </Button>
                </ButtonGroup>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Row className="mb-3">
              <label
                htmlFor="txtFirstNameBilling"
                className="col-lg-2 col-form-label font-weight-bold"
              >
                Rentals
              </label>
              <div className="col-lg-10">
                <ButtonGroup className="w-100">
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      rentalvalue === "Zero Brokerage"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() => handleRental("Zero Brokerage")}
                  >
                    Zero Brokerage
                  </Button>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      rentalvalue === "15 Day’s Rent Off"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() => handleRental("15 Day’s Rent Off")}
                  >
                    15 Day’s Rent Off
                  </Button>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      rentalvalue === "1 Month Rent Off"
                        ? "btnActive"
                        : "btnNormal"
                    }
                    onClick={() => handleRental("1 Month Rent Off")}
                  >
                    1 Month Rent Off
                  </Button>
                </ButtonGroup>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Row className="mb-3">
              <label
                htmlFor="txtFirstNameBilling"
                className="col-lg-2 col-form-label font-weight-bold"
              >
                Security Deposit
              </label>
              <div className="col-lg-10">
                <ButtonGroup className="w-100">
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      SDDiscount === "Only One Month Security Deposit"
                        ? "btnActive col-lg-5"
                        : "btnNormal col-lg-5"
                    }
                    onClick={() =>
                      handleSDDiscount("Only One Month Security Deposit")
                    }
                  >
                    Only One Month Security Deposit
                  </Button>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      SDDiscount === "10% Discount On Security Deposit"
                        ? "btnActive col-lg-5"
                        : "btnNormal col-lg-5"
                    }
                    onClick={() =>
                      handleSDDiscount("10% Discount On Security Deposit")
                    }
                  >
                    10% Discount On Security Deposit
                  </Button>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      SDDiscount === "30% Discount On Security Deposit"
                        ? "btnActive col-lg-5"
                        : "btnNormal col-lg-5"
                    }
                    onClick={() =>
                      handleSDDiscount("30% Discount On Security Deposit")
                    }
                  >
                    30% Discount On Security Deposit
                  </Button>
                </ButtonGroup>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Row className="mb-3">
              <label
                htmlFor="txtFirstNameBilling"
                className="col-lg-2 col-form-label font-weight-bold"
              >
                Home Services
              </label>
              <div className="col-lg-10">
                <ButtonGroup className="w-100">
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      homeServices === "Free Deep Cleaning Services"
                        ? "btnActive col-lg-5"
                        : "btnNormal col-lg-5"
                    }
                    onClick={() =>
                      handlehomeServices("Free Deep Cleaning Services")
                    }
                  >
                    Free Deep Cleaning Services
                  </Button>
                  <Button
                    style={{ marginRight: "5px", borderRadius: "5px" }}
                    className={
                      homeServices === "Free Painting Services"
                        ? "btnActive col-lg-5"
                        : "btnNormal col-lg-5"
                    }
                    onClick={() => handlehomeServices("Free Painting Services")}
                  >
                    Free Painting Services
                  </Button>
                </ButtonGroup>
              </div>
            </Row>
          </Col>
        </Row> */}
        {/* <Row>
          <Col md={12}>
            <Row className="mb-3 d-block">
              <label
                htmlFor="txtFirstNameBilling"
                className="col-lg-12 col-form-label font-weight-bold"
              >
                Enter Other Attractive Offers ( Max 500 Characters )
              </label>
              <div className="col-lg-12">
                <Input
                  type="textarea"
                  id="textarea"
                  onChange={e => {
                    textareachange(e)
                  }}
                  maxLength="500"
                  rows="3"
                  placeholder="Offers must be seperated by comma ,"
                />
                <span className="badgecount d-block badge-success text-end w-100">
                  {" "}
                  {textcount} / 500{" "}
                </span>
              </div>
            </Row>
          </Col>
        </Row> */}
      </React.Fragment>
    )
  }
)

export default RentBasicDetails
