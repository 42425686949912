import React, { useState, useEffect } from "react"
import { Button, ButtonGroup, Col, Input, Row } from "reactstrap"
import { HOST_URL } from "helpers/url_helper"
import { ApiPostMethodWithToken } from "helpers/withToken_helper"

const CommAmminitiesEdit = React.forwardRef(
  ({ propertytype, lookingfor, propData, propertyId }, ref) => {
    const propertyType2Value = localStorage.getItem("commpropertyType2")

    const flatfurnish = [
      { id: 1, value: "Dining Table", icon: "" },
      { id: 2, value: "Washing Machine", icon: "" },
      { id: 3, value: "Sofa", icon: "" },
      { id: 4, value: "Microwave", icon: "" },
      { id: 5, value: "Stove", icon: "" },
      { id: 6, value: "Refrigerator", icon: "" },
      { id: 7, value: "Water Purifier", icon: "" },
      { id: 8, value: "Gas Pipeline", icon: "" },
      { id: 9, value: "AC", icon: "" },
      { id: 10, value: "Bed", icon: "" },
      { id: 11, value: "TV", icon: "" },
      { id: 12, value: "Cupboard", icon: "" },
      { id: 13, value: "Geyser", icon: "" },
    ]
    const CommAmminities = [
      { id: 1, value: "CCTV", icon: "" },
      { id: 2, value: "Power Backup", icon: "" },
      { id: 3, value: "Furnishing", icon: "" },
      { id: 4, value: "UPS", icon: "" },
      { id: 5, value: "Central Air Conditioning", icon: "" },
      { id: 6, value: "Oxygen Duct", icon: "" },
      { id: 7, value: "Internet Connectivity", icon: "" },
      { id: 8, value: "Vastu Compliant", icon: "" },
      { id: 9, value: "Fire Extinguisher", icon: "" },
      { id: 10, value: "Fire sensors", icon: "" },
      { id: 11, value: "Security Personnel", icon: "" },
      { id: 12, value: "Water Storage", icon: "" },
      { id: 13, value: "DG Availability", icon: "" },
      { id: 14, value: "Cafeteria", icon: "" },
      { id: 15, value: "Reception Area", icon: "" },
      { id: 16, value: "Pantry", icon: "" },
      { id: 17, value: "Fire Noc Certified", icon: "" },
      { id: 18, value: "Occupancy Certificate", icon: "" },
    ]

    const CommAmminitiesArray = [
      { id: 1, value: "Water Storage", icon: "" },
      { id: 2, value: "Power Backup", icon: "" },
      { id: 3, value: "Internet Connectivity", icon: "" },
      { id: 4, value: "Vastu Compliant", icon: "" },
      { id: 5, value: "Security Personnel", icon: "" },
      { id: 6, value: "CCTV", icon: "" },
      { id: 7, value: "DG Availability", icon: "" },
      { id: 8, value: "Cafeteria", icon: "" },
    ]

    const PlotAmminities = [
      { id: 1, value: "Wire fencing", icon: "" },
      { id: 2, value: "Wall fencing", icon: "" },
      { id: 3, value: "Tar road", icon: "" },
      { id: 4, value: "Cement road", icon: "" },
      { id: 5, value: "Moorum road", icon: "" },
      { id: 6, value: "Raw road", icon: "" },
      { id: 7, value: "Borewell", icon: "" },
      { id: 8, value: "NMC water", icon: "" },
      { id: 9, value: "Shade", icon: "" },
      { id: 10, value: "Level", icon: "" },
      { id: 11, value: "Unlevel", icon: "" },
    ]

    const [commAmeniDetails, setCommAmeniDetails] = useState(
      propData?.amenities || {}
    )

    useEffect(() => {
      setCommAmeniDetails(prevDetails => ({
        ...prevDetails,
        propertyType1: propertytype,
        lookingTo: lookingfor,
      }))
    }, [propertytype, lookingfor])

    // console.log("commAmeniDetails===", commAmeniDetails)

    //   const handleChange = (inputName, value) => {
    //     setCommAmeniDetails(prevState => {
    //       const updatedAmenities = { ...prevState }
    //       if (updatedAmenities[inputName]) {
    //         if (updatedAmenities[inputName].includes(value)) {
    //           // If the value is already present, remove it
    //           updatedAmenities[inputName] = updatedAmenities[inputName].filter(
    //             item => item !== value
    //           )
    //         } else {
    //           // If the value is not present, add it
    //           updatedAmenities[inputName] = [...updatedAmenities[inputName], value]
    //         }
    //       } else {
    //         // If the inputName is not in the state, create it and set the value
    //         updatedAmenities[inputName] = [value]
    //       }
    //       return updatedAmenities
    //     })
    //   }

    const handleChange = (inputName, value) => {
      setCommAmeniDetails(prevState => {
        const updatedAmenities = { ...prevState }
        if (updatedAmenities[inputName]) {
          if (updatedAmenities[inputName].includes(value)) {
            updatedAmenities[inputName] = updatedAmenities[inputName].filter(
              item => item !== value
            )
          } else {
            updatedAmenities[inputName] = [
              ...updatedAmenities[inputName],
              value,
            ]
          }
        } else {
          updatedAmenities[inputName] = [value]
        }
        return updatedAmenities
      })
    }

    const handleSubmit = async () => {
      var status
      var response
      if (Object.keys(commAmeniDetails).length <= 0) {
        status = true
        return { status: status }
      } else {
        const url = `${HOST_URL}property/amenitiesdetails`
        const token = JSON.parse(localStorage.getItem("token"))
        const user_id = JSON.parse(localStorage.getItem("authUser"))
        const property_id = JSON.parse(localStorage.getItem("property_id"))
        const data = {
          user_id: propData?.user_id,
          property_id: propertyId || property_id,
          amenities: commAmeniDetails,
        }
        var response
        await ApiPostMethodWithToken(url, data, token).then(resp => {
          // console.log("commAmeniDetails----", commAmeniDetails)
          if (resp.error === false) {
            status = true
            response = resp
          } else {
            status = false
            response = resp
          }
        })
        return { status: status, message: response.message }
      }
    }

    React.useImperativeHandle(ref, () => ({
      handleCommAmm: () => handleSubmit(),
    }))

    return (
      <>
        <Row>
          <Col md={12}>
            <Row className="mb-3 d-block">
              <h5
                htmlFor="txtFirstNameBilling"
                className="col-lg-12 mb-3 font-weight-bold"
              >
                Add Amenities
              </h5>

              {propertyType2Value == "Plot" && (
                <div className="col-lg-12">
                  <ButtonGroup className="w-100 amenitiesgrid">
                    {PlotAmminities.map(face => (
                      <Button
                        key={face.id}
                        style={{ borderRadius: "5px" }}
                        className={
                          commAmeniDetails.commercialAmminities &&
                          commAmeniDetails.commercialAmminities.includes(
                            face.value
                          )
                            ? "btnActive"
                            : "btnNormal"
                        }
                        onClick={() =>
                          handleChange("commercialAmminities", face.value)
                        }
                      >
                        <Input
                          type="checkbox"
                          name="commercialAmminities"
                          value={face.value}
                          onChange={() =>
                            handleChange("commercialAmminities", face.value)
                          }
                          // checked={rrentAmeniDetails.commercialAmminities.includes(face.value)}
                          style={{ opacity: 0, position: "absolute" }}
                        />
                        {face.value}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
              )}

              {propertyType2Value !== "Retail Shop" &&
                propertyType2Value !== "Showroom" &&
                propertyType2Value !== "Plot" && (
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 amenitiesgrid">
                      {CommAmminities.map(face => (
                        <Button
                          key={face.id}
                          style={{ borderRadius: "5px" }}
                          className={
                            commAmeniDetails.commercialAmminities &&
                            commAmeniDetails.commercialAmminities.includes(
                              face.value
                            )
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handleChange("commercialAmminities", face.value)
                          }
                        >
                          <Input
                            type="checkbox"
                            name="commercialAmminities"
                            value={face.value}
                            onChange={() =>
                              handleChange("commercialAmminities", face.value)
                            }
                            // checked={rrentAmeniDetails.commercialAmminities.includes(face.value)}
                            style={{ opacity: 0, position: "absolute" }}
                          />
                          {face.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                )}

              {/* Render amenities based on property type */}
              {propertyType2Value !== "Office" &&
                propertyType2Value !== "Warehouse" &&
                propertyType2Value !== "Plot" &&
                propertyType2Value !== "Other" && (
                  <div className="col-lg-12">
                    <ButtonGroup className="w-100 amenitiesgrid">
                      {CommAmminitiesArray.map(face => (
                        <Button
                          key={face.id}
                          style={{ borderRadius: "5px" }}
                          className={
                            commAmeniDetails.commercialAmminities &&
                            commAmeniDetails.commercialAmminities.includes(
                              face.value
                            )
                              ? "btnActive"
                              : "btnNormal"
                          }
                          onClick={() =>
                            handleChange("commercialAmminities", face.value)
                          }
                        >
                          <Input
                            type="checkbox"
                            name="commercialAmminities"
                            value={face.value}
                            onChange={() =>
                              handleChange("commercialAmminities", face.value)
                            }
                            // checked={rrentAmeniDetails.commercialAmminities.includes(face.value)}
                            style={{ opacity: 0, position: "absolute" }}
                          />
                          {face.value}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                )}
            </Row>
          </Col>
        </Row>
      </>
    )
  }
)

export default CommAmminitiesEdit
